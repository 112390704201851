import { Col, Row, Container } from "reactstrap";
import IndexNavbar from "Components/Navbars/IndexNavbar.js";
import { useEffect} from 'react';
import "./About.scss";
import SubHeader from 'Components/SubHeader/SubHeader';
import Footers from "Components/Footers/Footers";
import {Helmet} from "react-helmet";
function About() {
  useEffect(() => {
    document.body.classList.toggle("about");
    return function cleanup() {
      document.body.classList.toggle("about");
    };
  }, []);
  const data = {
    title: "About Us",
    description1: "Krishpar prepare your business for change - delivering innovative solutions that answer real business problems. !",
    image:require("Assets/img/banner/about.jpg"),
    circlepop: false,
  }
  return (
    <>
    <Helmet>
        <title>About us - Krishpar Technologies </title>
    </Helmet>
     <SubHeader data={data}/>
        <IndexNavbar />
        <Container fluid id="Aboutus">
                    <Row>
                        <Col lg={7} md={12}>
                            <div className="aboutuspara">
                                <h1 data-aos="fade-right">Who We Are</h1>
                                <p data-aos="fade-right">
                                    Krishpar isn’t just a name. It’s an idea. It’s a force born with immense experience combined over a single vision, to drive the future with integrated HR solutions. Krishpar Technologies is one of the smart platforms that offers you end to end HR services with extremely personalized care. By constantly challenging conventions and thinking alternatively, it caters to your need with pinpoint accuracy and unmatched quality.
                                </p><br />
                                <p data-aos="fade-right">
                                    What makes this company, a class apart are its unique offerings. Sourcing out the right executive, recruiting the right candidate, comprehensive contract placements, payroll and even compliance management are some of its distinct core services designed to fuel your growth.
                                </p><br />
                                <p data-aos="fade-right">
                                    With Krishpar is on your side, you will have a concrete foundation that will support you to grow and go beyond to new leagues. It’s a versatile opportunity with limitless possibilities.
                                </p>
                            </div>
                        </Col>

                        <Col lg={5} md={12} className="aboutusimage">
                            <Row>
                                <Col>
                                    <div data-aos-anchor-placement="center-bottom" data-aos="zoom-in-left" data-aos-duration="1000">
                                        <img className="aboutimage" src={require("Assets/img/about/image4.jpg")} />
                                    </div>
                                </Col>
                                <Col >
                                    <div data-aos-anchor-placement="center-bottom" data-aos="zoom-in-left" data-aos-duration="1000">
                                        <img className="aboutimage aboutimages" src={require("Assets/img/about/image2.jpg")} />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {/* <div data-aos="zoom-in-left" data-aos-duration="1000">
                                    <img className="aboutimage" src={require("Assets/img/about/image4.jpg")} />
                                </div> */}
                                </Col>

                                <Col className="aboutimage">
                                    {/* <div data-aos="zoom-in-left" data-aos-duration="1000">
                                    <img src={require("Assets/img/about/image4.jpg")} />
                                </div> */}
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
                <Footers color="#000e23" />
      </>
  )
        }
        export default About;
