import React, { useEffect } from "react";
import "./ITSolutions.scss";
import SubHeader from "Components/SubHeader/SubHeader";
import IndexNavbar from "Components/Navbars/IndexNavbar.js";
import AOS from "aos";
import web from "../../Assets/img/It-Solution/web.png";
import app from "../../Assets/img/It-Solution/Mobile-Development.png"
import software from "../../Assets/img/It-Solution/software.webp"
import cloud from "../../Assets/img/It-Solution/cloudServices.webp"
import Services from "../../Assets/img/It-Solution/itservices.png"
import Footers from "Components/Footers/Footers";
import {Helmet} from "react-helmet";
function ItSolution() {
  useEffect(() => {
    AOS.init({
      duration: 1500, // values from 0 to 3000, with step 50ms
    });
    AOS.refresh({ duration: 2000 });
  }, []);
  const data = {
    title: "IT Solutions",
    description1:
    "In a world of complexity, where technology drives constant change, it’s more important than ever to work with the right people.  At the right time!. That’s where we come in! We provides innovative IT solutions that solve the challenges you face !",
    image:require("Assets/img/banner/it_solution.jpg"),
    circlepop:false,
  };

  return (
    <>
    <Helmet>
        <title>IT Solutions - Krishpar Technologies </title>
    </Helmet>
      <IndexNavbar />
      <SubHeader data={data} />
      <div className="IT-Solution">
        <div className="row staffing">
          <div className="col-md-6 doc" >
            <img src={web} alt="WebDevelopment"></img>
          </div>
          <div className="col-md-6 staff" >
            <h3>Web Development</h3>
            <p>
              We involves <b>designing, coding, and maintaining</b> websites that
              are accessible over the internet. Web development encompasses
              various aspects, including front-end development, back-end
              development, and database management. It involves using <b>HTML, CSS,</b>
              and <b>JavaScript</b> to create the visual and interactive elements that
              users see and interact with in their web browsers. Front-end
              developers also work with frameworks and libraries like <b>React,
              Angular, or Vue.js</b> to build more complex and dynamic web
              applications.They also work with databases such as <b>MySQL,
              PostgreSQL, or MongoDB</b> to store and retrieve data.
            </p>
          </div>
        </div>
      </div>
      <div className="IT-Solution">
        <div className="row staffing development flex-sm-column-reverse flex-md-row">
          <div className="col-md-6 staff" >
            <h3>Application Development</h3>
            <p>
              We involves creating applications specifically for a
              particular <b>mobile platform</b> such as <b>iOS</b> or <b>Android</b>. Native apps are
              designed and optimized to run directly on the target platform,
              providing the best performance and user experience. Native
              development allows access to <b>platform-specific features</b> and <b>APIs</b>,
              enabling developers to leverage the full potential of the
              device.Using web technologies like <b>HTML5, CSS,</b> and <b>JavaScript</b>. Web
              apps can be <b>responsive</b>, adapting to different screen sizes, or
              they can be designed specifically for mobile devices.
            </p>
          </div>
          <div className="col-md-6 doc" >
            <img src={app} alt="MobileDevelopment"></img>
          </div>
        </div>
      </div>
      {/* <div className="IT-Solution">
        <div className="row staffing">
          <div className="col-md-6 doc" data-aos="fade-right">
            <img src={software} alt="SoftwareDevelopment"></img>
          </div>
          <div className="col-md-6 staff" data-aos="fade-left">
            <h3>Software Development</h3>
            <p>
              We refers the process of designing, coding, testing, and
              maintaining computer software. It involves creating programs,
              applications, or systems that meet specific user requirements and
              solve particular problems.In this stage, software architects and
              designers create a high-level design of the system. They define
              the software's structure, components, modules, and their
              interactions.We use programming languages, frameworks, and
              tools to build the software's functionality, following best
              practices and coding standards.
            </p>
          </div>
        </div>
      </div> */}
      <div className="IT-Solution">
        <div className="row staffing">
        <div className="col-md-6 doc" >
            <img src={cloud} alt="Cloud"></img>
          </div>
          <div className="col-md-6 staff" >
            <h3>Cloud services & Data Management</h3>
            <p>
              We closely related and often go hand in hand. Cloud services
              refer to the delivery of computing resources, including <b>servers,
              storage, databases, networking,</b> and <b>software applications</b>, over
              the internet. These services are provided by cloud service
              providers, such as <b>Amazon Web Services (AWS)</b>, <b>Microsoft Azure</b>, and
              <b>Google Cloud Platform (GCP)</b>.Cloud service providers typically
              offer robust infrastructure with built-in redundancy and failover
              mechanisms. This ensures high availability and minimizes downtime.
            </p>
          </div>
          
        </div>
      </div>
      <div className="IT-Solution">
        <div className="row support staffing flex-sm-column-reverse flex-md-row">
          
          <div className="col-md-6 staff" >
            <h3 style={{}}>IT Support 24X7</h3>
            <p>
              We Support <b>24x7</b> refers to round-the-clock technical
              assistance provided to users or organizations to address their
              IT-related issues and concerns at <b>any time, day or night</b>. It
              ensures continuous support and availability to resolve IT
              problems, minimize downtime, and maintain the smooth operation of
              IT systems. IT support teams typically operate in shifts or have
              on-call technicians available during non-business hours. This
              ensures that someone is available to address critical incidents or
              emergencies that may arise outside regular working hours.
            </p>
          </div>
          <div className="col-md-6 doc" >
            <img src={Services} alt="ITSupport"></img>
          </div>
        </div>
      </div>
      <Footers color="#fff"/>
    </>
  );
}

export default ItSolution;
