import React, { useEffect } from "react";
import "../Industries/Industries.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import IndexNavbar from "Components/Navbars/IndexNavbar.js";
import SubHeader from "Components/SubHeader/SubHeader";
import "Components/Default/default.css";
import Insurance from "Components/Insurance/Insurance.js"
import Information from "Components/InformationTechnology/Information.js"
import Automobile from "Components/Automobile/Automobile.js"
import Construction from "Components/Construction/construction.js"
import Manufacturing from "Components/Manufacturing/manufacturing.js"
import HealthCare from "Components/HealthCare/healthcare.js"
import Engineering from "Components/Engineering/engineering.js"
import Logistics from "Components/Logistics/logistics.js"
import Finance from "Components/Finance/finance.js"
import Entertainment from "Components/Entertainment/entertainment.js"
import Retail from "Components/Retail/retail.js"
import Footers from "Components/Footers/Footers";
import {Helmet} from "react-helmet";
function Industries() {
  useEffect(() => {
    AOS.init({
      duration: 1500, // values from 0 to 3000, with step 50ms
    });
    AOS.refresh({ duration: 2000 });
  }, []);

  const data = {
    title: "Industries Served",
    description1:
      "Make your business prosper with our great team of experts .Well make your new business plan a success !",
      image:require("Assets/img/banner/industry-banner.jpg"),
  };
  return (
    <>
    <Helmet>
        <title>Industries Served - Krishpar Technologies </title>
    </Helmet>
      <IndexNavbar />
      <SubHeader data={data} />
      <div className={'industries-served-page'}>
        <div
          // style={{
          //   backgroundImage:
          //     "url(../../Assets/img/industries-served/Industries-Banner.jpg) ",
          // }}
          className="industries-banner"
        >
          <IndexNavbar />
        </div>
          <Information/>
          <Automobile/>
          <Insurance/>
          <Construction/>
          <Manufacturing/>
          <HealthCare/>
          <Engineering/>
          <Logistics/>
          <Finance/>
          <Entertainment/>
          <Retail/>
          
       
      </div>
      <Footers color="#fff"/>
    </>
  );
}

export default Industries;
