import React from "react";
import logitics from "../../Assets/img/industries-served/logitics.gif";
import ToggleVisibility from "Components/Default/ToggleVisibility.js";
import "Components/Default/default.css";
import Default from "Components/Default/default.js";
import LogToggle from "Components/Logistics/logtoggle.js"
/* React component where show/hide 
  functionality is implemented */
export default function Logistics() {
  return (
    <div className="industryalign">
          <div className="row it logistics flex-sm-column-reverse flex-md-row" style={{ marginTop: "10px" }}>
            <div className="col-md-6 in" data-aos="fade-right">
              <LogToggle/>
            </div>
            <div className="col-md-6 tics" data-aos="fade-left">
              <img src={logitics} alt="auto" className="doc"></img>
            </div>
          </div>
        </div>
  );
}   