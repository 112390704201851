import React, { useState } from "react";
export default function AutoToggle() {
  // React state to manage visibility
  const [show, setShow] = useState(true);

  // const comp1 = () => {
  //   <div>
  //     <button onClick={toggleShow}>Back</button>
  //     <p>para 1</p>
  //   </div>;
  // };

  // const comp2 = () => {
  //   return (
  //     <div>
  //       <button onClick={toggleShow}>Tech</button>
  //       <p>para 2</p>
  //     </div>
  //   );
  // };

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);
  }
  // var buttonText = show ? "Technology Contributors" : "Back";

  return (
    <div className="component-container">
      {/* {show && children}
      <button onClick={toggleShow}>{buttonText}</button> */}
      {show ? (
        <div>
          <h3>Automobile</h3>
          <p>
            The automotive industry's embrace of software technologies and
            digital solutions has led to unprecedented growth and innovation.
            From connected cars and autonomous driving to augmented reality and
            big data analytics, these advancements are redefining the industry's
            future. As software continues to play a pivotal role in shaping the
            automotive landscape, manufacturers and consumers alike can look
            forward to a safer, smarter, and more sustainable driving
            experience.
          </p>
          <button className="button" onClick={toggleShow}>
            Click for Technology Contributors
          </button>
        </div>
      ) : (
        <div>
          <div className>
            <h2 className="contributors">Technology Contributors</h2>
            <ol>
              <li>
                <b>1.Advanced Driver Assistance Systems (ADAS):</b>Sensor
                Fusion, Computer Vision, Data Fusion and Signal Processing.
              </li>
              <li>
                <b>2.Electric Vehicle (EV) Software:</b>Battery Management
                Systems (BMS), Electric Motor Control Systems, Vehicle-to-Grid
                (V2G) Integration.{" "}
              </li>
              <li>
                <b>3.Vehicle-to-Everything (V2X) Communication:</b>Cellular
                Vehicle-to-Everything (C-V2X) Technology, Dedicated Short-Range
                Communication (DSRC).
              </li>
            </ol>
          </div>
          <button className="button" onClick={toggleShow}>
            Back
          </button>
        </div>
      )}
    </div>
  );
}
