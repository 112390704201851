import React, { useState } from "react";
import Default from "Components/Default/default.js";
import construction from "../../Assets/img/industries-served/construction.gif";
export default function Healthtoggle() {
  // React state to manage visibility
  const [show, setShow] = useState(true);

  // const comp1 = () => {
  //   <div>
  //     <button onClick={toggleShow}>Back</button>
  //     <p>para 1</p>
  //   </div>;
  // };

  // const comp2 = () => {
  //   return (
  //     <div>
  //       <button onClick={toggleShow}>Tech</button>
  //       <p>para 2</p>
  //     </div>
  //   );
  // };

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);
  }
  // var buttonText = show ? "Technology Contributors" : "Back";

  return (
    <div className="component-container">
      {/* {show && children}
      <button onClick={toggleShow}>{buttonText}</button> */}
      {show ? (
        <div>
          <h3>HealthCare</h3>
          <p>
            The healthcare industry is experiencing significant transformation
            with the integration of software technologies and digital solutions.
            These advancements are revolutionizing patient care, improving
            operational efficiency, and enabling better healthcare outcomes.
            Here are some of the latest software technologies and digital
            solutions used in the healthcare industry
          </p>
          <button className="button" onClick={toggleShow}>
          Click for Technology Contributors
          </button>
        </div>
      ) : (
        <div>
          <div className>
            <h2 className="contributors">Technology Contributors</h2>
            <ol>
              <li>
                <b>Electronic Health Records (EHR):</b>Data Integration and
                Interoperability, Customizable Templates and Workflow
                Automation.
              </li>
              <li>
                <b>2.Blockchain Technology:</b>Blockchain as a Service (BaaS)
                Solutions, Healthcare Solutions.
              </li>
              <li>
                <b>3.Artificial Intelligence (AI) and Machine Learning (ML):</b>
                Machine Learning Frameworks, Clinical Decision Support Systems
                (CDSS), Computer Vision Systems.
              </li>
              <li>
                <b>4.Health Information Exchange (HIE):</b>Data Integration and
                Aggregation, Consent Management, Secure Data Exchange and
                Messaging.
              </li>
            </ol>
          </div>
          <button className="button" onClick={toggleShow}>
            Back
          </button>
        </div>
      )}
    </div>
  );
}
