import React from "react";

const Project = (props) =>{
    const {img} = props.item;
    return(
        <div className="Project">
            <img src={img} alt="img"/>
            <div className="disc">
                {/* <h1>Description</h1> */}
                
            </div>
        </div>
    )
}

export default Project;