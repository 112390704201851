import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "Assets/css/nucleo-icons.css";
import "Assets/css/theme.css";
import "Assets/scss/blk-design-system-react.scss";
import "Assets/css/custom.css";

import Home from "Pages/Home/Home";
import HRSolution from "Pages/HRSolutions/HRSolutions";
import About from "Pages/About/About";
import Industries from "Pages/Industries/Industries";
import Contact from "Pages/ContactUs/Contact";
import Product from "Pages/Product/Product";
import ItSolution from "Pages/ITSolutions/ITSolutions";
import Itstaffing from "Components/Itstaffing/itstaffing"
import Krishpar from "Components/WhyKrishpar/WhyKrishpar"
import Login from "Components/Login/Login";
import Register from "Components/Register/Register";
import Hrsolution from "Pages/HrSolution/Hrsolution";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/aboutus" element={<About />} />
      <Route path="/solutions" element={<HRSolution />} />
      <Route path="/industries-served" element={<Industries />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/emploi-product" element={<Product />} />
      <Route path="/itsolutions" element={<ItSolution/>}/>
      <Route path="/whykrishpar" element={<Krishpar/>}/>
      <Route path="/login" element={<Login/>}/>
      <Route path="/register" element={<Register/>}/>
      <Route path="/itstaffing" element={<Itstaffing/>}/>
    </Routes>
  </BrowserRouter>
);
