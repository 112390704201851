import React, { useState } from "react";
import Default from "Components/Default/default.js";
import construction from "../../Assets/img/industries-served/construction.gif";
export default function EngineeringToggle() {
  // React state to manage visibility
  const [show, setShow] = useState(true);

  // const comp1 = () => {
  //   <div>
  //     <button onClick={toggleShow}>Back</button>
  //     <p>para 1</p>
  //   </div>;
  // };

  // const comp2 = () => {
  //   return (
  //     <div>
  //       <button onClick={toggleShow}>Tech</button>
  //       <p>para 2</p>
  //     </div>
  //   );
  // };

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);
  }
  // var buttonText = show ? "Technology Contributors" : "Back";

  return (
    <div className="component-container">
      {/* {show && children}
      <button onClick={toggleShow}>{buttonText}</button> */}
      {show ? (
        <div>
          <h3>Engineering</h3>
          <p>
            The engineering industry is undergoing a digital transformation,
            embracing software technologies and digital solutions to adapt to
            the latest trends and enhance productivity. Here's a small write-up
            about the latest software technologies and digital solutions used in
            the engineering industry
          </p>
          <button className="button" onClick={toggleShow}>
           Click for Technology Contributors
          </button>
        </div>
      ) : (
        <div>
          <div className  >
            <h2 className="contributors">Technology Contributors</h2>
            <ol>
              <li>
                <b>1.Computer-Aided Engineering (CAE) Software:</b>Multiphysics
                Simulation, High-Performance Computing (HPC), Cloud Computing
                and Remote Simulation.
              </li>
              <li>
                <b>2.Internet of Things (IoT) in Engineering:</b>
                IoT Development Kits and SDKs, Cloud Services, Digital Twin
                Software.
              </li>
              <li>
                <b>3.Computer Numerical Control (CNC) Software:</b>
                Computer-Aided Design (CAD) Software, Computer-Aided
                Manufacturing (CAM) Software.
              </li>
              <li>
                <b>4.Computer-Aided Design (CAD) Software:</b>Parametric
                Modeling, 3D Modeling and Visualization.
              </li>
            </ol>
          </div>
          <button className="button" onClick={toggleShow}>
            Back
          </button>
        </div>
      )}
    </div>
  );
}
