import React from "react";
import Wave from "react-wavify";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import "../Footers/Footers.scss";


function Footers(props) {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
    <div className="Footerbackground" style={{background:props.color}}>
      <div className={'footer_section'}>
        <Wave
          className="wave"
          fill="#1277b0"
          paused={false}
          options={{
            height: 0.5,
            amplitude: 40,
            speed: 0.15,
            points: 5,
          }}
        />
        <footer className="footer">
          <ul className="social-icon">
            <li className="social-icon__item">
              <a className="social-icon__link" href="https://www.facebook.com/profile.php?id=100068809818075">
              <i className="fab fa-facebook" />
              </a>
            </li>
            {/* <li className="social-icon__item">
              <a className="social-icon__link" href="https://twitter.com/i/flow/login?redirect_after_login=%2FKrishparL">
              <i className="fab fa-twitter" />
              </a>
            </li> */}
            <li className="social-icon__item">
              <a className="social-icon__link" href="https://www.linkedin.com/company/krishpar-technologies-pvt-ltd">
              <i className="fab fa-linkedin" />
              </a>
            </li>
            {/* <li className="social-icon__item">
              <a className="social-icon__link" href="https://www.instagram.com/krishpar_technologies/?igshid=5julkdpmkp0u">
              <i className="fab fa-instagram" />
              </a>
            </li> */}
          </ul>
          <ul className="menu">
            <li className="menu__item">
              <a className="menu__link" href="/aboutus">
                About Us
              </a>
            </li>
            <li className="menu__item">
              <a className="menu__link" href="/emploi">
                Emploi
              </a>
            </li>
            <li className="menu__item">
              <a className="menu__link" href="/industries-served">
                Industries Served
              </a>
            </li>
            <li className="menu__item">
              <a className="menu__link" href="/solutions">
                HR Solution
              </a>
            </li>
            <li className="menu__item">
              <a className="menu__link" href="/itstaffing">
                IT Staffing
              </a>
            </li>
            <li className="menu__item">
              <a className="menu__link" href="/itsolutions">
                IT Solution
              </a>
            </li>
            <li className="menu__item">
              <a className="menu__link" href="/contact">
                Contact Us
              </a>
            </li>
          </ul>
          <p className="par text-center">&copy;2023 Krishpar.com | All Rights Reserved</p>
        </footer>
      </div>
      </div>
    </>
  );
}
export default Footers;
