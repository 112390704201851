import React from "react";
import "../Register/Register.css";
import { Row, Container, Col } from "reactstrap";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IndexNavbar from "Components/Navbars/IndexNavbar.js";
function Register() {
  return (
    <>
      <div className="register">
        <IndexNavbar />
        <div>
          <div className="glass">
            <Container className="glass2">
              <div className="view4">
                <div>
                  <h1 className="head">Register</h1>
                </div>
                <Row>
                  <Col>
                    <div className="line1">
                      <TextField
                        id="standard-basic"
                        label="First Name"
                        variant="standard"
                        required
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="line2">
                      <TextField
                        id="standard-basic"
                        label="Last Name"
                        variant="standard"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="line3">
                      <TextField
                        id="standard-basic"
                        label="Email"
                        variant="standard"
                        required
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="line4">
                      <TextField
                        id="standard-basic"
                        label="Mobile Number"
                        variant="standard"
                        required
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="line3">
                      <TextField
                        id="standard-basic"
                        label="Aadhar Number"
                        variant="standard"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="var">
                  <Button variant="contained" disableElevation>
                    Submit
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
export default Register;
