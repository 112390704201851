import '../Loading/Loading.scss';
import { useEffect, useState } from 'react';

const Loading = () => {

  const [playAnimation, setPlayAnimation] = useState(false);

    
    useEffect(() => {
    const onPageLoad = () => {
      setPlayAnimation(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad);
      
      return () => window.removeEventListener('load', onPageLoad);
    }
    }, []);

  return (

    <>
        <div className='animated-loader'>
            <div className='animated-box-1'></div>
            <div className='animated-box-2'></div>
            <div className='animated-box-3'></div>
            <div className='animated-box-4'></div>
        </div>
    </>

  )
}

export default Loading

// import React from 'react'

// const Loading = () => {

//     const loadingStyles = {
//         width: '100vw',
//         height: '100vh',
//         backgroundColor: '#F06446'
//     }

//   return (
//     <div style={loadingStyles}></div>
//   )
// }

// export default Loading