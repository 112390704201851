import React from "react";
import entertainment from "../../Assets/img/industries-served/entertainment.gif";
import EnterToggle from "Components/Entertainment/entertoggle.js"
import "Components/Default/default.css";

/* React component where show/hide 
  functionality is implemented */
export default function Entertainment() {
  return (
    <div className="industryalign">
          <div className="row it entertainment flex-sm-column-reverse flex-md-row" style={{ marginTop: "10px" }}>
            <div className="col-md-6 in" data-aos="fade-right">
              
              <EnterToggle/>
            </div>
            <div className="col-md-6 ment" data-aos="fade-left">
              <img src={entertainment} alt="auto" className="doc"></img>
            </div>
          </div>
        </div>
  );
}   