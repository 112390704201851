import React from "react";
import "Components/Projects/Slider.scss";
import SliderComp from "./Slide";

const Projects = () =>{
    return(
        <div className="Projects">
            <h2>A Glimpse of our product - Emploi</h2>
            <div className="slide">
                <SliderComp/>
            </div>
        </div>
    )
}

export default Projects;

