import { color, motion, useAnimation } from "framer-motion";
import React, { useState, useEffect } from "react";
import useIntersect from "./IntersectionObserver";
import "../Hero/Hero.scss";

import headerLinePhone from "Assets/img/bannerline.svg";
import HRProcess from "Assets/img/animations/hr-process.gif";
import { Container, Row, Col } from "reactstrap";

function Hero() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [ref, entry] = useIntersect({});
  const control = useAnimation();

  const contentLeftMainCard = {
    hidden: { opacity: 0, y: 100 },
    show: {
        opacity: 1,
      y: 0,
      transition: {
        duration: screenSize > 768 ? 0.5 : 1,
        delayChildren: 0.3,
        delay: screenSize > 768 ? 0 : 0.5,
      },
    },
  };
  const contentLeftCard1 = {
    hidden: { opacity: 0, y: 50 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: screenSize > 768 ? 0.5 : 1,
      },
    },
  };
  const contentLeftCard2 = {
    hidden: { opacity: 0, y: 70 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: screenSize > 768 ? 0.5 : 0,
      },
    },
  };
  const contentLeftCard3 = {
    hidden: { opacity: 0, y: 90 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: screenSize > 768 ? 0.7 : 0,
      },
    },
  };

  const contentRightTop = {
    hidden: {
      opacity: 0,
      scale: screenSize > 768 ? 0.99 : 1,
      y: screenSize > 768 ? 0 : 70,
    },
    show: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: screenSize > 768 ? 1 : 0,
      },
    },
  };

  const contentRightBottom = {
    hidden: {
      opacity: 0,
      y: screenSize > 768 ? 0 : 70,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: screenSize > 768 ? 2 : 0.5,
      },
    },
  };

  const contentRest = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 1,
        delay: screenSize > 768 ? 2 : 0.5,
      },
    },
  };

  useEffect(() => {
    window.onresize = () => {
      setScreenSize(window.innerWidth);
    };
    if (entry.isIntersecting) {
      control.start("show");
    }
    if (!entry.isIntersecting) {
      control.start("hidden");
    }
  });
  const handleview = (event) => {
    // document.getElementById("demo1").innerHTML = "A trustable liaison in industry that bridges the gap between renowned organization and valuable employee";
    event.currentTarget.classList.add("demo", "demo1");
  };
  return (
    <div className="why-krishpar header-filter">
      <div className="squares square1" />
      <div className="squares square2" />
      <div className="squares square3" />
      <div className="squares square4" />
      <div className="squares square5" />
      <div className="squares square6" />
      <div className="squares square7" />
      <Container fluid className="p-5">
        <h2 className="why">WhyKrishpar?</h2>
        <Row className="content p-5 justify-content-center align-items-center">
          <Col md="4">
            <div className="content__left">
              <motion.ul
                variants={contentLeftMainCard}
                initial="hidden"
                animate="show"
                style={{ listStyle: "none" }}
              >
                <div className="content__left--main-card">
                  <motion.div variants={contentRest}>
                  </motion.div>
                  <motion.div
                    variants={contentRest}
                    ref={ref}
                    animate={control}
                    initial="hidden"
                    className="mobile-view-bg-img"
                  >
                    <img
                      src={headerLinePhone}
                      alt="line-img-mobile"
                      className="mobile-line-img"
                    />
                  </motion.div>

                  <img src={HRProcess} alt="floristImg" className="main-img" />

                  <motion.li
                    variants={contentLeftCard1}
                    initial="hidden"
                    animate={control}
                    className="content__left--card-1"
                    ref={ref}
                    onClick={handleview}
                  >
                    <div className="text-box">
                      <p className="title d-none">1</p>
                    </div>
                  </motion.li>

                  <motion.li
                    variants={contentLeftCard2}
                    initial="hidden"
                    animate={control}
                    className="content__left--card-2"
                    ref={ref}
                    // onClick={handleview1}
                  >
                    <img
                      className="d-none"
                      src="https://raw.githubusercontent.com/barlydjaja/asdf/492b59ca24b8e1bb79066c836d3c8f357d6f48b7/src/Assets/images/jpg/header-img-gardener.jpg"
                      alt="floristImg"
                    />
                    <div className="text-box">
                      <p className="title d-none">2</p>
                      <p className="place d-none">Gardener in CheChe</p>
                    </div>
                  </motion.li>

                  <motion.li
                    variants={contentLeftCard3}
                    initial="hidden"
                    animate={control}
                    className="content__left--card-3"
                    ref={ref}
                    // onClick={handleview2}
                  >
                    <img
                      className="d-none"
                      src="https://rawcdn.githack.com/barlydjaja/asdf/492b59ca24b8e1bb79066c836d3c8f357d6f48b7/src/Assets/images/jpg/header-img-barista.jpg"
                      alt="floristImg"
                    />
                    <div className="text-box">
                      <p className="title d-none">3</p>
                      <p className="place d-none">Cafe in Bangkok</p>
                    </div>
                  </motion.li>
                </div>
              </motion.ul>
            </div>
          </Col>
          <Col md="1"></Col>
          <Col md="6">
            <div className="content__right">
              <motion.div
                variants={contentRightTop}
                initial="hidden"
                animate="show"
              >
                <p className="demo">
                  A perfect partner for organizations to recruit the best
                  employee
                </p>
                <hr />
                <p className="demo1">
                  A trustable liaison in industry that bridges the gap between
                  renowned organization and valuable employee
                </p>
                <hr />
                <p className="demo2">
                  A suitable navigator for individuals to land in growth
                  oriented career
                </p>
              </motion.div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Hero;
