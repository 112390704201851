import React, { useEffect, useRef } from "react";
import SubHeader from "Components/SubHeader/SubHeader";
import Projects from "Components/Projects/Projects";
import IndexNavbar from "Components/Navbars/IndexNavbar";
import Section from "Components/Sections/Section";
import "Components/WaterEffect/WaterEffect.css";
import Footers from "Components/Footers/Footers";
import {Helmet} from "react-helmet";

function Display() {

    const data = {
        title: "EMPLOI",
        description1:
            "Make your business prosper with our great team of experts .Well make your new business plan a success !",
        image: require("../Product/productbanners.jpg")
    };

    return (
        <div className="">
      <Helmet>
        <title>Emploi_Product - Krishpar Technologies </title>
    </Helmet>
            <IndexNavbar />
            <SubHeader data={data} />
            <Section />
            <Projects />
            <Footers color="#000e23"/>
            

        </div>
    )
}

export default Display;


