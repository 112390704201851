import React, { useState } from "react";
import Default from "Components/Default/default.js";
import construction from "../../Assets/img/industries-served/construction.gif";
import Aos from "aos";
import "aos/dist/aos.css"
export default function EnterToggle() {
  // React state to manage visibility
  const [show, setShow] = useState(true);
  useState(() => {
    Aos.init({ duration: 1500 });
}, []);

  // const comp1 = () => {
  //   <div>
  //     <button onClick={toggleShow}>Back</button>
  //     <p>para 1</p>
  //   </div>;
  // };

  // const comp2 = () => {
  //   return (
  //     <div>
  //       <button onClick={toggleShow}>Tech</button>
  //       <p>para 2</p>
  //     </div>
  //   );
  // };

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);
  }
  // var buttonText = show ? "Technology Contributors" : "Back";

  return (
    <div className="component-container">
      {/* {show && children}
      <button onClick={toggleShow}>{buttonText}</button> */}
      {show ? (
        <div>
          <div className>
          <h3>Entertainment</h3>
            <p>
              The entertainment industry is undergoing a rapid transformation
              fueled by cutting-edge software technologies and innovative
              digital solutions. These advancements have revolutionized how
              content is created, distributed, and consumed, enabling the
              industry to adapt to the latest trends and cater to evolving
              consumer preferences. In this write-up, we explore the software
              technologies and digital solutions that are reshaping the
              entertainment industry and driving its growth.
            </p>
          </div>
          <button className="button" onClick={toggleShow}>
           Click for Technology Contributors
          </button>
        </div>
      ) : (
        <div  >
          <div className>
            <h2 className="contributors">Technology Contributors</h2>
            <ol>
              <li>
               <b>1.Cloud Computing and Storage:</b>Scalable Infrastructure, Collaborative Workflows.
              </li>
              <li><b>2.Blockchain:</b>Data Security and Privacy, Supply Chain and Authenticity Verification.</li>
              <li><b>3.Digital Rights Management (DRM):</b>Content Protection, Secure Distribution Channels</li>
            </ol>
          </div>
          <button className="button" onClick={toggleShow}>
            Back
          </button>
        </div>
      )}
    </div>
  );
}
