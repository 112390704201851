import React from "react";
import "../Login/Login.css";
import TextField from "@mui/material/TextField";
import { Row, Container, Col, type } from "reactstrap";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import IndexNavbar from "Components/Navbars/IndexNavbar.js";

function Login() {
  return (
    <div className="img">
      <IndexNavbar />
      <Container>
        <div className="view5">
          <div className="contact1">
            <h1>Login</h1>
          </div>
          <div className="contactus">
            <Row className="body">
              <Col lg={12}>
                <div className="basic">
                  <TextField
                    id="standard-basic"
                    label="Username"
                    variant="standard"
                    required
                  />
                </div>
                <div className="basic">
                  <TextField
                    id="standard-basic"
                    label="Password"
                    variant="standard"
                    required
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div>
                  <FormGroup className="box">
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Remember me"
                      required
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col className="img-pin">
              <a href="">Forgot Password?</a>
              </Col>
            </Row>
            <div className="box2">
              <Button variant="contained" disableElevation>
                Login
              </Button>
            </div>
            <Row>
              <Col className="check">
                <p>
                  Don't have registration yet?
                  <a href="/register">Register now</a>
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
}
export default Login;
