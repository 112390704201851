import React from "react";
import "../ContactUs/Contact.css";
import IndexNavbar from "Components/Navbars/IndexNavbar.js";
import SubHeader from "Components/SubHeader/SubHeader.js";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Col, Container, Row, Button } from "reactstrap";
import Aos from "aos";
import "aos/dist/aos.css";
import Section2 from "Components/Sections/Section2";
import Footers from "Components/Footers/Footers";
import {Helmet} from "react-helmet";
function Contact() {
  const data = {
    title: "Contact Us",
    description1:
      "Make your business prosper with our great team of experts .Well make your new business plan a success !",
    image:require("Assets/img/banner/Contact-Us.jpg")
  };
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
   
    <div>
       <Helmet>
        <title>Contact us - Krishpar Technologies </title>
    </Helmet>
      <IndexNavbar />
      <SubHeader data={data} />
      {/* <div>
        <h1 className="Contact1" data-aos="fade-left">
          Free Project Quote
        </h1>
      </div>
      <div className="row-1">
        <p data-aos="fade-right">
          Do you have any questions? Are you ready to reduce cost and create new
          revenue?
        </p>
        <p data-aos="fade-left">
          Or you simply want to say hi? Drop us a message.
        </p>
      </div> */}
      <div className="contactusss">
        <Section2/>
      </div>
      <div className="banner2">
        <Container id="heading">
          <Row>
            <Col md={12} lg={6}>
              <div className="len1">
                <h2 data-aos="fade-right">Registered Office</h2>
                <p className="p1" data-aos="fade-right">
                  <span>
                    {/* <FontAwesomeIcon
                      className="fade1"
                      icon={faLocationDot}
                      data-aos="fade-right"
                    />{" "} */}
               
                    KRISHPAR TECHNOLOGIES PVT LTD
                  </span>
                </p>
                <p className="p2" data-aos="fade-right">
                  295 H.I.G Bharthi Salai, 1st Floor Nolambur Phase 2,
                </p>
                <p className="p2" data-aos="fade-right">
                  Mogappair West Garden, Chennai-600 037.
                </p>
                <p className="parcon" data-aos="fade-right">
                  {/* <FontAwesomeIcon className="fade1" icon={faPhoneVolume} />  */}
                  +91 99624 55328
                </p>
                <p className="par2" data-aos="fade-right">
                <i className="fab fa-envelope" />
                  {/* <FontAwesomeIcon className="fade1" icon={faEnvelope} />{" "} */}
                  connect@krishpar.com
                </p>
              </div>
            </Col>
            <Col className="banner">
              <div className="len2">
                <h2 className="head2" data-aos="fade-left">
                  Coimbatore Branch
                </h2>
                <p className="p1" data-aos="fade-left">
                  <span>
                    {/* <FontAwesomeIcon className="icons" icon={faLocationDot} />{" "} */}
                    KRISHPAR TECHNOLOGIES PVT LTD
                  </span>
                </p>
                <p className="p4" data-aos="fade-left">
                  No.16-B, E&E Industrial Estate, Civil Aerodrome Post,
                </p>
                <p className="p4" data-aos="fade-left">
                  SITRA, Coimbatore – 641014.
                </p>
                <p className="par3" data-aos="fade-left">
                  {/* <FontAwesomeIcon
                    className="icons para"
                    icon={faPhoneVolume}
                  />{" "} */}
                  +91 99624 55328
                </p>
                <p className="group" data-aos="fade-left">
                  {/* <FontAwesomeIcon className="icons para" icon={faEnvelope} />{" "} */}
                  connect@krishpar.com
                </p>
              </div>
              {/* <img data-aos="fade-left" src={registerimage} /> */}
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7772.693655777094!2d80.171264!3d13.077192000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5263559c5682b1%3A0x41f09521851bad04!2sKrishpar%20Technologies%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1687153556133!5m2!1sen!2sin"
                className="map1"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                data-aos="fade-left"
              ></iframe>
            </Col>
            <Col md={12} lg={6}>
              <div className="banner1">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7831.990647624387!2d77.036234!3d11.038977!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba8578f929836fd%3A0x27c22b5e9b08d698!2sThe%20South%20India%20Textile%20Research%20Association(SITRA)-%20NABL%20accredited%20Testing%20Lab%20-%20PPE%2C%20Fabric%2C%20Medical%20Textile%2C%20Fiber%20%26%20Yarn!5e0!3m2!1sen!2sin!4v1687169225778!5m2!1sen!2sin"
                  className="map2"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  data-aos="fade-right"
                ></iframe>
                {/* <img data-aos="fade-right" src={registerimage1} /> */}
              </div>
            </Col>
          </Row>
        </Container>
        {/* <div className="banner2"> */}
        {/* </div> */}
      </div>
      <Footers color="#fff"/>
    </div>
  );
}
export default Contact;
