import React from "react";
import retail from "../../Assets/img/industries-served/retail.gif";
import RetailToggle from "Components/Retail/retailtoggle.js"
import "Components/Default/default.css";

/* React component where show/hide 
  functionality is implemented */
export default function Retail() {
  return (
    <div className="industryalign">
    <div className="row it " style={{ marginTop: "10px" }}>
      <div className="col-md-6 re"data-aos="fade-right">
        <img src={retail} alt="informtech" className="doc"></img>
      </div>
      <div className="col-md-6 in" data-aos="fade-left">
        <RetailToggle/>
      
      </div>
    </div>
  </div>
  );
}   