import React from "react";
import construction from "../../Assets/img/industries-served/construction.gif";
import ToggleVisibility from "Components/Default/ToggleVisibility.js";
import "Components/Default/default.css";
/* React component where show/hide 
  functionality is implemented */
export default function Construction() {
  return (
    <div className="industryalign">
    <div className="row it construction flex-sm-column-reverse flex-md-row" style={{ marginTop: "10px" }}>
      <div className="col-md-6 in" div data-aos="fade-right">
        
        <ToggleVisibility/>
           {/* <ToggleVisibility>
        <Default />
      </ToggleVisibility> */}
      </div>
      <div
        className="col-md-6 build"
        div
        data-aos="fade-left"
        style={{ marginTop: "10px" }}
      >
        <img src={construction} alt="auto" className="doc"></img>
      </div>
    </div>
  </div>
  );
}   