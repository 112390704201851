import React, { useState } from "react";
import "../Timelineproduct/Timelineproduct.css"
import { Container, Row, Col } from "reactstrap";
import img1 from "./Timelineproductimages/icons8-resume-100.png";
import img2 from "./Timelineproductimages/icons8-name-tag-100.png";
import img3 from "./Timelineproductimages/icons8-database-administrator-100.png";
import img4 from "./Timelineproductimages/icons8-gps-100.png";
import img5 from "./Timelineproductimages/icons8-filtering-100.png";
import img6 from "./Timelineproductimages/icons8-calendar-100.png";
import img7 from "./Timelineproductimages/icons8-people-100.png";
import img8 from "./Timelineproductimages/icons8-email-100.png";
import img9 from "./Timelineproductimages/icons8-analytics-100.png";
import img10 from "./Timelineproductimages/icons8-cyber-security-100.png";
import wave1 from "./Timelineproductimages/process-line-6.png";
import wave2 from "./Timelineproductimages/process-line-2.png";
import wave3 from "./Timelineproductimages/process-line-8.png";
import Aos from "aos";
import "aos/dist/aos.css"

function Timelineproduct() {

    useState(() => {
        Aos.init({ duration: 1500 });
    }, []);

    return (
        <div id="wrap" className="wrap">
            <div className="productbackground">
                <Container >
                    <Row>
                        <img data-aos="fade-up" data-aos-duration="3000" className="wave1" src={wave1} alt="design" />
                        <Col lg={6} md={12} className="emploiFeatures resume">
                            <div className="leftside">
                                <img data-aos="fade-right" className="images" width='25%' src={img1} alt="resume-parsing" />
                                <h1 data-aos="fade-right">Resume Parsing</h1>
                                <p data-aos="fade-right">EMPLOI can automatically extract and parse relevant information from resumes, such as contact details, work experience, education, skills, and certifications. This feature eliminates the need for manual data entry and speeds up the candidate screening process.</p>
                            </div>

                        </Col>
                        <Col lg={6} md={12}>

                        </Col>
                    </Row>
                </Container>

                <Container >
                    <Row>
                        <img data-aos="fade-in" className="wave2" src={wave2} alt="design" />
                        <Col lg={6} md={12}>

                        </Col>
                        <Col lg={6} md={12} className="emploiFeatures jobposting">
                            <div className="leftside">
                                <img data-aos="fade-left" className="images" width='25%' src={img2} alt="resume-parsing" />
                                <h1 data-aos="fade-left">Job Posting and Distribution</h1>
                                <p data-aos="fade-left">EMPLOI allows recruiters to create job postings and distribute them to multiple job boards, career websites, social media platforms, and internal portals. This feature helps expand the reach of job ads and attract a larger pool of qualified candidates.</p>
                            </div>

                        </Col>
                    </Row>
                </Container>

                <Container >
                    <Row>
                        <img data-aos="fade-in" className="wave3" src={wave3} alt="design" />
                        <Col lg={6} md={12} className="emploiFeatures database">
                            <div className="leftside">
                                <img data-aos="fade-right" className="images" width='25%' src={img3} alt="resume-parsing" />
                                <h1 data-aos="fade-right">Candidate Database Management</h1>
                                <p data-aos="fade-right">EMPLOI provides a centralized database to store candidate profiles, resumes, and application materials. Recruiters can easily search, filter, and sort candidates based on specific criteria, such as skills, qualifications, location, or experience.</p>
                            </div>

                        </Col>
                        <Col lg={6} md={12}>

                        </Col>
                    </Row>
                </Container>

                <Container >
                    <Row>
                        <img data-aos="fade-in" className="wave2" src={wave2} alt="design" />
                        <Col lg={6} md={12}>

                        </Col>
                        <Col lg={6} md={12} className="emploiFeatures tracking">
                            <div className="leftside">
                                <img data-aos="fade-left" className="images" width='25%' src={img4} alt="resume-parsing" />
                                <h1 data-aos="fade-left">Application Tracking and Status Updates</h1>
                                <p data-aos="fade-left">EMPLOI tracks the progress of each candidate throughout the hiring process. Recruiters can view the status of applications, including whether a candidate has been shortlisted, interviewed, or hired. It enables easy monitoring and management of candidate pipelines.</p>
                            </div>

                        </Col>
                    </Row>
                </Container>

                <Container >
                    <Row>
                        <img data-aos="fade-in" className="wave3" src={wave3} alt="design" />
                        <Col lg={6} md={12} className="emploiFeatures screening">
                            <div className="leftside">
                                <img data-aos="fade-right" className="images" width='25%' src={img5} alt="resume-parsing" />
                                <h1 data-aos="fade-right">Candidate Screening and Filtering</h1>
                                <p data-aos="fade-right">EMPLOI offers tools to screen and filter candidates based on specific criteria, such as keywords, skills, experience, or education. This feature allows recruiters to quickly identify the most relevant candidates and prioritize their evaluation.</p>
                            </div>

                        </Col>
                        <Col lg={6} md={12}>

                        </Col>
                    </Row>
                </Container>

                <Container >
                    <Row>
                        <img data-aos="fade-in" className="wave2" src={wave2} alt="design" />
                        <Col lg={6} md={12}>

                        </Col>
                        <Col lg={6} md={12} className="emploiFeatures scheduling">
                            <div className="leftside">
                                <img data-aos="fade-left" className="images" width='25%' src={img6} alt="resume-parsing" />
                                <h1 data-aos="fade-left">Interview Scheduling and Calendar Integration</h1>
                                <p data-aos="fade-left">EMPLOI often includes features for scheduling interviews, sending automated invitations to candidates, and syncing with recruiters' calendars. It simplifies the process of coordinating interview times and eliminates scheduling conflicts.</p>
                            </div>

                        </Col>
                    </Row>
                </Container>

                <Container >
                    <Row>
                        <img data-aos="fade-in" className="wave3" src={wave3} alt="design" />
                        <Col lg={6} md={12} className="emploiFeatures communication">
                            <div className="leftside">
                                <img data-aos="fade-right" className="images" width='25%' src={img7} alt="resume-parsing" />
                                <h1 data-aos="fade-right">Communication and Collaboration</h1>
                                <p data-aos="fade-right">EMPLOI facilitates communication and collaboration among recruiters, hiring managers, and other stakeholders involved in the hiring process. It allows for internal messaging, interview feedback sharing, and notifications to keep everyone informed.</p>
                            </div>

                        </Col>
                        <Col lg={6} md={12}>

                        </Col>
                    </Row>
                </Container>

                <Container >
                    <Row>
                        <img data-aos="fade-in" className="wave2" src={wave2} alt="design" />
                        <Col lg={6} md={12}>

                        </Col>
                        <Col lg={6} md={12} className="emploiFeatures email">
                            <div className="leftside">
                                <img data-aos="fade-left" className="images" width='25%' src={img8} alt="resume-parsing" />
                                <h1 data-aos="fade-left">Automated Email Responses</h1>
                                <p data-aos="fade-left">EMPLOI can send automated email responses to candidates at different stages of the hiring process, such as acknowledging receipt of applications or notifying candidates about interview outcomes. This feature ensures consistent and timely communication with candidates.</p>
                            </div>

                        </Col>
                    </Row>
                </Container>

                <Container >
                    <Row>
                        <img data-aos="fade-in" className="wave3" src={wave3} alt="design" />
                        <Col lg={6} md={12} className="emploiFeatures reporting">
                            <div className="leftside">
                                <img data-aos="fade-right" className="images" width='25%' src={img9} alt="resume-parsing" />
                                <h1 data-aos="fade-right">Reporting and Analytics</h1>
                                <p data-aos="fade-right">EMPLOI generates reports and analytics on various recruitment metrics, including time-to-fill, source of hire, candidate demographics, and recruitment channel effectiveness. These insights help recruiters track performance, identify bottlenecks, and make data-driven decisions.</p>
                            </div>

                        </Col>
                        <Col lg={6} md={12}>

                        </Col>
                    </Row>
                </Container>

                <Container >
                    <Row>
                        <img data-aos="fade-in" className="wave2" src={wave2} alt="design" />
                        <Col lg={6} md={12}>

                        </Col>
                        <Col lg={6} md={12} className={"mb-5"} className="emploiFeatures compliance">
                            <div className="leftside">
                                <img data-aos="fade-left" className="images" width='25%' src={img10} alt="resume-parsing" />
                                <h1 data-aos="fade-left">Compliance and Data Security</h1>
                                <p data-aos="fade-left">EMPLOI platforms often include features to ensure compliance with data protection regulations, such as secure storage, access controls, and data encryption. They help safeguard candidate information and maintain data privacy.</p>
                            </div>

                        </Col>
                    </Row>
                </Container>

            </div>


        </div>
    )
}

export default Timelineproduct;