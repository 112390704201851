import { useEffect, Suspense, useState } from "react";
import IndexNavbar from "Components/Navbars/IndexNavbar.js";
import Hero from "Components/Hero/Hero";
import Showcase from "Components/Showcase/Showcase";
import Industries from "Components/Industries/Industries";
import Loading from "Components/Loading/Loading";
import ProductBanner from "Components/ProductBanner/ProductBanner";
import RippleEffect from "Components/WaterEffect/WaterEffect";
import Footers from "Components/Footers/Footers";
import { Container, Row, Col, Button } from "reactstrap";
import TextField from "@mui/material/TextField";
import Emploi from "Components/Emploi/emploi";
import axios from "axios";
import Alert from 'react-bootstrap/Alert';
import Alerts from "Components/Alert/alert.js"
export default function Home() {

  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',

  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleInput = () => {
    setFormData({
      name: '',
      email: '',
      mobile: '',
      message: ''
    });
  }

  const validateEmail = (email) => {
    // Simple email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  function formSubmit() {
    if (!validateEmail(formData.email)) {
      setAlertMessage('Please enter a valid email address.');
      setTimeout(() => {
        setAlertMessage('');
      }, 3000);
      return;
    }

    console.log("Form Submit Trigger");
    axios.get(
      'https://emploi.krishpar.com/api/jobs/mail/trigger',
      {
        params: {
          auth: "eyJpdiI6IlBXK3dqNlZGbWFDWC9KM2dpckc1K1E9PSIsInZhbHVlIjoiZXlTbm9EeHV1THdLck5lSU42N2Z4d0Q1YjFpbGJxc015VXhaa2tQUURpWT0iLCJtYWMiOiJhYTQzNzUwZjBjZTQyZTAzZGYzZGRhNmNkY2MwNmU0M2ZhYjlhMGVhNTkwN2E5Zjc0MWVmNDE2NDJiZmUzMDNiIiwidGFnIjoiIn0=",
          data: formData
        },
      }).then(response => {
        console.log("Response Received");
        console.log(response);
        handleInput();
        // alert("hello");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        // alert("Hello, world!");
      }).catch(error => {
        console.log("Error Response Received");
        console.log(error);
        // alert("hello");
      });
    // alert("ThankYou for submitting the form");
  }

  useEffect(() => {
    document.body.classList.toggle("index-page");

    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
  }, []);

  // function formSubmit(){
  //   console.log("Form Submit Trigger");
  //   axios.get(
  //       'https://emploi.krishpar.com/api/jobs/mail/trigger', 
  //   {
  //       params: {
  //           auth:"eyJpdiI6IlBXK3dqNlZGbWFDWC9KM2dpckc1K1E9PSIsInZhbHVlIjoiZXlTbm9EeHV1THdLck5lSU42N2Z4d0Q1YjFpbGJxc015VXhaa2tQUURpWT0iLCJtYWMiOiJhYTQzNzUwZjBjZTQyZTAzZGYzZGRhNmNkY2MwNmU0M2ZhYjlhMGVhNTkwN2E5Zjc0MWVmNDE2NDJiZmUzMDNiIiwidGFnIjoiIn0=",
  //           data: formData
  //       },
  //   }).then(response => {
  //       console.log("Response Received");
  //       console.log(response);
  //       alert("Hello, world!");

  //   }).catch(error => {
  //       console.log("Error Response Received");
  //       console.log(error);
  //       alert("hello");
  //       setShowAlert(true);
  //       setTimeout(() => {
  //         setShowAlert(false);
  //       }, 3000);
  //   });
  // }
  return (
    <div className="App">
      <Loading />
      <Suspense fallback={<Loading />}>
        <IndexNavbar />
        <div className="wrapper">
          <div className="main">
            <div className="relate">
              <ProductBanner />

              {/* <div className="abso">
                      <RippleEffect />
                    </div> */}
            </div>
            <Showcase />
            <Hero />
            <Industries />
            <div className="container">
              <Row className="justify-content-center align-items-center footer-newsletter">
                <Col className="footer-content" lg={"8"}>
                  <div className={"p-5"}>
                    <h4 className="Ready">Ready To Get Started?</h4>
                    <h4 className="Talk">Talk to Us</h4>
                  </div>
                </Col>
                <Col className="f-form" lg={"4"}>



                  <TextField
                    data-aos="fade-right"
                    id="standard-basic"
                    label="Name *"
                    variant="standard"
                    className="mb-4 d-block"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />

                  <TextField
                    data-aos="fade-left"
                    id="standard-basic"
                    label="Email *"
                    variant="standard"
                    className="mb-4 d-block"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  <TextField
                    data-aos="fade-left"
                    id="standard-basic"
                    label="Message *"
                    variant="standard"
                    className="mb-4 d-block"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                  <Button type="submit" data-aos="zoom-in" onClick={(e) => formSubmit()}>Send</Button>
                  {showAlert && <Alerts />}
                  {alertMessage && <Alert style={{ background: "#d64242" }} variant="error">{alertMessage}</Alert>}

                </Col>
              </Row>
            </div>
          </div>
          <Footers />
        </div>
      </Suspense>
    </div>
  );
}
