import React from "react";
import finance from "../../Assets/img/industries-served/finance.gif";
import Financetoggle from "Components/Finance/financetoggle.js"
import "Components/Default/default.css";
/* React component where show/hide 
  functionality is implemented */
export default function Finance() {
  return (
    <div className="industryalign">
          <div className="row it " style={{ marginTop: "10px" }}>
            <div className="col-md-6 fin" data-aos="fade-right">
              <img src={finance} alt="informtech" className="doc"></img>
            </div>
            <div className="col-md-6 in" data-aos="fade-left">
             <Financetoggle/>
            
            </div>
          </div>
        </div>
  );
}   