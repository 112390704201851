import React from "react";
import "../Industries/Industries.scss";
import InformationTechnology from "../.././Assets/img/industries/InformationTechnology.png";
import Automobile from "../.././Assets/img/industries/automobile.png";
import Construction from "../.././Assets/img/industries/Construction.png";
import Engineering from "../.././Assets/img/industries/Engineering.png";
import Finance from "../.././Assets/img/industries/Finance.png";
import Entertainment from "../.././Assets/img/industries/Entertainment.png";
import Insurance from "../.././Assets/img/industries/Insurance.png";
import Manufacturing from "../.././Assets/img/industries/Manufacturing.png";
import HealthCare from "../.././Assets/img/industries/HealthCare.png";
import Logistics from "../.././Assets/img/industries/Logistics.png";
import Retail from "../.././Assets/img/industries/Retail.png";

function Industries() {
  return (
    <>
      <div className="container-fluid industries-section">
        <div className="industries-served">
          <h2 className="industrieshead">Industries Served</h2>
          <p className="industriesparagar">
            Krishpar offers an undisputed staffing solutions and recruitment
            services to a wide range of industries by thoroughly understanding
            their manpower need and help them to recruit the assetable employee
            at a cost optimized way.
          </p>
        </div>
        <div style={{ paddingBottom: "10px" }}>
          <div
            className="row text-center industries"
            style={{ paddingTop: "-80%", marginBottom: "20px" }}
          >
            <div className="col-md-2 inform">
              <a href="/industries-served">
                <div className="mm-iconbox__icon-wrapper">
                  <img
                    src={InformationTechnology}
                    className="img-responsive icon-img"
                    alt="Information"
                  />
                  <span className="information">IT</span>
                </div>
              </a>
            </div>
            <div className="col-md-2 Automobile">
              <a href="/industries-served">
                <div className="mm-iconbox__icon-wrapper">
                  <img
                    src={Automobile}
                    className="img-responsive icon-img"
                    alt="Automobile"
                  />
                  <span>Automobile</span>
                </div>
              </a>
            </div>
            <div className="col-md-2 Construction">
              <a href="/industries-served">
                {" "}
                <div className="mm-iconbox__icon-wrapper">
                  <img
                    src={Construction}
                    className="img-responsive icon-img"
                    alt="Construction"
                  />
                  <span>Construction</span>
                </div>
              </a>
            </div>
            <div className="col-md-2 Engineering">
              <a href="/industries-served">
                {" "}
                <div className="mm-iconbox__icon-wrapper">
                  <img
                    src={Engineering}
                    className="img-responsive icon-img"
                    alt="Engineering"
                  />
                  <span>Engineering</span>
                </div>
              </a>
            </div>
            <div className="col-md-2 Finance">
              <a href="/industries-served">
                <div className="mm-iconbox__icon-wrapper">
                  <img
                    src={Finance}
                    className="img-responsive icon-img"
                    alt="Finance"
                  />
                  <span>Finance</span>
                </div>
              </a>
            </div>
            <div className="col-md-2 Entertainment">
              <a href="/industries-served">
                <div className="mm-iconbox__icon-wrapper">
                  <img
                    src={Entertainment}
                    className="img-responsive icon-img"
                    alt="Entertainment"
                  />
                  <span>Entertainment</span>
                </div>
              </a>
            </div>
          </div>
          <div
            className="row text-center1 industries"
            style={{
              padding: "10px",
              marginBottom: "20px",
              marginTop: "-1  0px",
            }}
          >
            <div className="col-md-1" />
            <div className="col-md-2 Insurance">
              <a href="/industries-served">
                <div className="mm-iconbox__icon-wrapper">
                  <img
                    src={Insurance}
                    className="img-responsive icon-img"
                    alt="Insurance"
                  />
                  <span>Insurance</span>
                </div>
              </a>
            </div>
            <div className="col-md-2 Manufacturing">
              <a href="/industries-served">
                {" "}
                <div className="mm-iconbox__icon-wrapper">
                  <img
                    src={Manufacturing}
                    className="img-responsive icon-img"
                    alt="Manufacturing"
                  />
                  <span>Manufacturing</span>
                </div>
              </a>
            </div>
            <div className="col-md-2 Health">
              <a href="/industries-served">
                <div className="mm-iconbox__icon-wrapper">
                  <img
                    src={HealthCare}
                    className="img-responsive icon-img"
                    alt="Health"
                  />
                  <span>HealthCare</span>
                </div>
              </a>
            </div>
            <div className="col-md-2 Logistics">
              <a href="/industries-served">
                <div className="mm-iconbox__icon-wrapper">
                  <img
                    src={Logistics}
                    className="img-responsive icon-img"
                    alt="Logistics"
                  />
                  <span>Logistics</span>
                </div>
              </a>
            </div>
            <div className="col-md-2 Retail">
              <a href="/industries-served">
                <div className="mm-iconbox__icon-wrapper">
                  <img
                    src={Retail}
                    className="img-responsive icon-img"
                    alt="Retail"
                  />
                  <span>Retail</span>
                </div>
              </a>
            </div>
            <div className="col-md-1" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Industries;
