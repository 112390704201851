import React, { useState } from "react";
import Default from "Components/Default/default.js";
import construction from "../../Assets/img/industries-served/construction.gif";
export default function Financetoggle() {
  // React state to manage visibility
  const [show, setShow] = useState(true);

  // const comp1 = () => {
  //   <div>
  //     <button onClick={toggleShow}>Back</button>
  //     <p>para 1</p>
  //   </div>;
  // };

  // const comp2 = () => {
  //   return (
  //     <div>
  //       <button onClick={toggleShow}>Tech</button>
  //       <p>para 2</p>
  //     </div>
  //   );
  // };

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);
  }
  // var buttonText = show ? "Technology Contributors" : "Back";

  return (
    <div className="component-container">
      {/* {show && children}
      <button onClick={toggleShow}>{buttonText}</button> */}
      {show ? (
        <div>
          <h3>Finance</h3>
          <p>
            The finance industry is rapidly evolving with the advent of advanced
            software technologies and digital solutions. These innovations are
            reshaping traditional financial processes, enhancing customer
            experiences, and driving operational efficiency. In this write-up,
            we explore the latest software technologies and digital solutions
            that are enabling the finance industry to adapt to the latest trends
            and meet the evolving needs of customers.
          </p>
          <button className="button" onClick={toggleShow}>
           Click for Technology Contributors
          </button>
        </div>
      ) : (
        <div>
          <div className>
            <h2 className="contributors">Technology Contributors</h2>
            <ol>
              <li>
                <b>1.Artificial Intelligence (AI) and Machine Learning (ML):</b>
                Chatbots and Virtual Assistants, Fraud Detection and Prevention,
                Process Automation and Efficiency.
              </li>
              <li>
                <b>2.Open Banking and API Integration:</b>RESTful APIs
                (Representational State Transfer), OAuth 2.0 (Open
                Authorization), Webhooks.
              </li>
              <li>
                <b>3.Digital Wealth Management:</b>Robust Security
                Infrastructure, Robust Security Infrastructure.
              </li>
            </ol>
          </div>
          <button className="button" onClick={toggleShow}>
            Back
          </button>
        </div>
      )}
    </div>
  );
}
