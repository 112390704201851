import React, { useState } from "react";
import Default from "Components/Default/default.js";
import construction from "../../Assets/img/industries-served/construction.gif";
export default function Manufacttogle() {
  // React state to manage visibility
  const [show, setShow] = useState(true);

  // const comp1 = () => {
  //   <div>
  //     <button onClick={toggleShow}>Back</button>
  //     <p>para 1</p>
  //   </div>;
  // };

  // const comp2 = () => {
  //   return (
  //     <div>
  //       <button onClick={toggleShow}>Tech</button>
  //       <p>para 2</p>
  //     </div>
  //   );
  // };

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);
  }
  // var buttonText = show ? "Technology Contributors" : "Back";

  return (
    <div className="component-container">
      {/* {show && children}
      <button onClick={toggleShow}>{buttonText}</button> */}
      {show ? (
        <div>
          <h3>Manufacturing</h3>
          <p>
            The manufacturing industry is undergoing a significant
            transformation with the integration of software technologies and
            digital solutions. These innovations are revolutionizing traditional
            manufacturing processes, enabling companies to adapt to the latest
            trends and stay competitive in a rapidly changing landscape. In this
            write-up, we explore some of the cutting-edge software technologies
            and digital solutions that are driving the manufacturing industry
            towards increased efficiency, flexibility, and productivity.
          </p>
          <button className="button" onClick={toggleShow}>
            click for Technology Contributors
          </button>
        </div>
      ) : (
        <div>
          <div className>
            <h2 className="contributors">Technology Contributors</h2>
            <ol>
              <li>
                <b>1.Enterprise Resource Planning (ERP) Systems:</b> Cloud-Based
                ERP, Mobile ERP, Internet of Things (IoT) Integration, Robotic
                Process Automation (RPA).
              </li>
              <li>
                <b>
                  2.Computer-Aided Design/Computer-Aided Manufacturing
                  (CAD/CAM):
                </b>
                Parametric Modeling, Additive Manufacturing (3D Printing),
                Toolpath Generation.
              </li>
              <li>
                <b>3.Industrial Internet of Things (IIoT):</b>Edge Computing,
                Big Data Analytics, Digital Twin Technology.
              </li>
              <li>
                <b>4.Product Lifecycle Management (PLM):</b>Cloud-Based PLM,
                Integration with CAD and CAE, Mobile PLM.
              </li>
            </ol>
          </div>
          <button className="button" onClick={toggleShow}>
            Back
          </button>
        </div>
      )}
    </div>
  );
}
