import React, { useState } from "react";
import Default from "Components/Default/default.js";
import construction from "../../Assets/img/industries-served/construction.gif";
export default function Intoggle() {
  // React state to manage visibility
  const [show, setShow] = useState(true);

  // const comp1 = () => {
  //   <div>
  //     <button onClick={toggleShow}>Back</button>
  //     <p>para 1</p>a
  //   </div>;
  // };

  // const comp2 = () => {
  //   return (
  //     <div>
  //       <button onClick={toggleShow}>Tech</button>
  //       <p>para 2</p>
  //     </div>
  //   );
  // };

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);
  }
  // var buttonText = show ? "Technology Contributors" : "Back";

  return (
    <div className="component-container">
      {/* {show && children}
      <button onClick={toggleShow}>{buttonText}</button> */}
      {show ? (
        <div >
         <h3>Insurance</h3>
              <p>
                In today's rapidly evolving digital landscape, the insurance
                industry is experiencing a profound shift towards embracing
                cutting-edge software technologies and innovative digital
                solutions. This transformative journey enables insurance
                companies to streamline their operations, enhance customer
                experiences, improve risk assessment, and stay competitive in a
                rapidly changing market. In this article, we will explore some
                of the latest software technologies and digital solutions
                revolutionizing the insurance industry, enabling it to adapt to
                the latest trends.
              </p>
          <button className="button" onClick={toggleShow}>
           Click for Technology Contributors
          </button>
        </div>
      ) : (
        <div>
          <div className>
            <h2 className="contributors">Technology Contributors</h2>
            <ol>
              <li>
                <b>1.Artificial Intelligence (AI) and Machine Learning
                (ML):</b> TensorFlow,PyTorch,scikit-learn,Apache Spark,Microsoft
                Cognitive Toolkit (CNTK).
              </li>
              <li><b>2.Internet of Things (IoT):</b> IoT Platforms,Edge Computing,IoT Development Frameworks.</li>
              <li><b>3.Blockchain:</b> CoinGecko, Chainalysis, Etherscan.</li>
              <li><b>4.Robotic Process Automation (RPA): </b> Intelligent Process Automation (IPA), Process Discovery and Mining Tools.</li>
            </ol>
          </div>
          <button className="button" onClick={toggleShow}>
            Back
          </button>
        </div>
      )}
     
    </div>
  );
}
