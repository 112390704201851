import React from "react";
import auto from "../../Assets/img/industries-served/auto.gif";
import AutoToggle from "Components/Automobile/AutoToggle.js"

export default function Automobile() {
  return (
    <div className="industryalign">
      <div className="row it automobile flex-sm-column-reverse flex-md-row" style={{ marginTop: "10px" }}>
        <div className="col-md-6 in" div data-aos="fade-right">
          <AutoToggle/>
        </div>
        <div className="col-md-6 mobile" div data-aos="fade-left">
          <img src={auto} alt="auto" className="doc" />
        </div>
      </div>
    </div>
  );
}   