import React, { useEffect } from "react";
import "./Itstaffing.scss";
import AOS from "aos";
import SubHeader from "Components/SubHeader/SubHeader";
import IndexNavbar from "Components/Navbars/IndexNavbar.js";
import Footers from "Components/Footers/Footers";
// import {View, Text} from "module";
import {Helmet} from "react-helmet";
function Itstaffing() {
  useEffect(() => {
    AOS.init({
      duration: 1500, // values from 0 to 3000, with step 50ms
    });
    AOS.refresh({ duration: 2000 });
  }, []);

  const data = {
    title: "IT Staffing",
    description1:
      "Unmatched Recruitment Services Tailored to Your Needs - Choose KrishPar for Success!",
    image: require("Assets/img/Itstaffing/staffing.jpg"),
    circlepop: false,
  };

  return (
    <>
    <Helmet>
        <title>IT Staffing - Krishpar Technologies </title>
    </Helmet>
      <IndexNavbar />
      <SubHeader data={data} />
      <div className="ITstaffing">
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            margin: "40px",
          }}
        >
          {/* <div style={{ flex: 1, height: 1, backgroundColor: "black" }} /> */}
          <div>
            <h1 style={{ textAlign: "center" }}>
              Krishpar's IT Staffing Services
            </h1>
          </div>
          <div style={{ flex: 1, height: 1, backgroundColor: "black" }} />
        </div>

        {/* <p>
          Krishpar offers a flexible workforce that can be scaled up or down as
          per project requirements. IT companies benefit from this approach as
          it enables them to access niche skills and industry experts,
          optimizing resource allocation and minimizing overhead costs.
        </p> */}
        <div className="row staffing">
          <div className="col-md-8">
            <ul className="order ">
              <p>
                <b>Krishpar</b> offers two primary services in terms of
                Staffing:
              </p>
              <li>
                <b>Contractual Staffing</b>
                <p>
                  <b>Contractual staffing</b> refers to the practice of hiring
                  skilled professionals for a specific duration, often tied to a
                  project's lifecycle. <b>Krishpar</b> offers a flexible
                  workforce that can be scaled up or down as per project
                  requirements.
                </p>
              </li>
              <li>
                <b>Permanent Staffing</b>
                <p>
                  <b>Permanent staffing</b> refers to a hire that is for
                  permanent, full-time roles and positions.
                </p>
              </li>
            </ul>

            <ul className="itservices">
              <p>
                Our successful track record of fulfilling our clients’
                requirement of IT professionals includes:
              </p>
              <li>
                <b>Cloud Computing</b>
              </li>
              <li>
                <b>DevOps</b>
              </li>
              <li>
                <b>Artificial Intelligence (AI) and Machine Learning (ML)</b>
              </li>
              <li>
                <b>Internet of Things (IoT)</b>
              </li>
              <li>
                <b>Cybersecurity Solutions</b>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <img src={require("Assets/img/Itstaffing/staff.gif")} />
          </div>
        </div>
      </div>

      <div className="ITstaffing">
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            margin: "40px",
          }}
        >
          <div style={{ flex: 1, height: 1, backgroundColor: "black" }} />
          <div>
            <h1 style={{ textAlign: "center" }}>
              Role of Contractual Staffing in Diversified Industries
            </h1>
          </div>
          {/* <div style={{ flex: 1, height: 1, backgroundColor: "black" }} /> */}
        </div>

        {/* <p>
          Krishpar offers a flexible workforce that can be scaled up or down as
          per project requirements. IT companies benefit from this approach as
          it enables them to access niche skills and industry experts,
          optimizing resource allocation and minimizing overhead costs.
        </p> */}
        <div className="row staffing">
          <div className="col-md-4">
            <img src={require("Assets/img/Itstaffing/it-staff.gif")} />
          </div>
          <div className="col-md-8">
            <ol className="order">
              <li>
                Krishpar offers a flexible workforce that can be scaled up or
                down as per project requirements. IT companies benefit from this
                approach as it enables them to access niche skills and industry
                experts, optimizing resource allocation and minimizing overhead
                costs.
              </li>
              <li>
                <b>Technology and Software Development:</b> IT companies rely
                heavily on contractual staffing to bolster their technology and
                software development projects. Our front-end developers to data
                scientists, contractual staff brings expertise to spearhead
                innovation and meet tight deadlines.
              </li>
              <li>
                <b>Healthcare and Life Sciences:</b> In the healthcare and life
                sciences sectors, IT plays a pivotal role in driving
                advancements.Krishpar ensures these industries can tap into
                specialized IT skills for data management, telemedicine
                solutions, and digital health platforms.
              </li>
              <li>
                <b>Finance and Banking:</b> Financial institutions are
                leveraging contractual IT staff to stay ahead in the digital
                transformation race. We assist in implementing secure and
                efficient banking systems, including fintech solutions,
                artificial intelligence-driven analytics, and blockchain
                technology.
              </li>
              <li>
                <b>Retail and E-commerce:</b> In the competitive world of retail
                and e-commerce, We offer contractual staff to develop and
                maintain robust online platforms, customer relationship
                management systems, and personalized marketing tools.
              </li>
              <li>
                <b>Manufacturing and Automotive:</b> IT-driven automation and
                IoT technologies have become indispensable in manufacturing and
                automotive industries. We aids in implementing and maintaining
                these cutting-edge technologies to optimize production processes
                and enhance supply chain management.
              </li>
            </ol>
          </div>
        </div>
      </div>

      <div className="ITstaffing">
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            display: "flex",
            margin: "40px",
          }}
        >
          {/* <div style={{ flex: 1, height: 1, backgroundColor: "black" }} /> */}
          <div>
            <h1 style={{ textAlign: "center" }}>
              Why Choose Krishpar As a Contractual Staffing Provider?
            </h1>
          </div>
          <div style={{ flex: 1, height: 1, backgroundColor: "black" }} />
        </div>

        {/* <p>
          When considering contractual staffing services, IT companies should
          assess various providers to make the best choice. We are the key
          factors to consider in a competitive analysis:
        </p> */}
        <div className="row itstaff">
          <div className="col-md-8">
            <ol className="order">
              <li>
                When considering contractual staffing services, IT companies
                should assess various providers to make the best choice. Below
                are the key factors which is why Krishpar should become your
                Contractual Staffing Partner
              </li>
              <li>
                <b>Industry Experience:</b> Krishpar as a staffing provider has
                experience in catering to the IT needs of various industries. We
                ensure that your specific requirements are understood can match
                suitable candidates accordingly.
              </li>
              <li>
                <b>Screening and Selection Process:</b> Krishpar’s in-house
                screening and selection process include technical assessments
                and behavioral evaluations to ensures high-quality candidates
                are provided
              </li>
              <li>
                <b>Scalability and Flexibility:</b> Krishpar being a reputable
                staffing provider offers scalability to adjust resources
                according to project demands, ensuring optimal resource
                allocation and cost savings.
              </li>
            </ol>
          </div>
          <div className="col-md-4">
            <img src={require("Assets/img/Itstaffing/IT-Staffing.gif")} />
          </div>
        </div>
      </div>
      <Footers color="#fff" />
    </>
  );
}

export default Itstaffing;
