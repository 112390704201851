import React, { useState } from "react";
import Default from "Components/Default/default.js";
import construction from "../../Assets/img/industries-served/construction.gif";
export default function LogToggle() {
  // React state to manage visibility
  const [show, setShow] = useState(true);

  // const comp1 = () => {
  //   <div>
  //     <button onClick={toggleShow}>Back</button>
  //     <p>para 1</p>
  //   </div>;
  // };

  // const comp2 = () => {
  //   return (
  //     <div>
  //       <button onClick={toggleShow}>Tech</button>
  //       <p>para 2</p>
  //     </div>
  //   );
  // };

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);
  }
  // var buttonText = show ? "Technology Contributors" : "Back";

  return (
    <div className="component-container">
      {/* {show && children}
      <button onClick={toggleShow}>{buttonText}</button> */}
      {show ? (
        <div>
          <h3>Logistics</h3>

          <p>
            In today's fast-paced world, the logistics industry is constantly
            evolving to meet the growing demands of global trade and supply
            chains. To stay competitive and efficient, logistics companies are
            increasingly turning to software technologies and digital solutions
            that enable streamlined operations, enhanced visibility, and
            improved customer experiences. This article explores some of the
            latest software technologies and digital solutions that are
            revolutionizing the logistics industry, empowering businesses to
            adapt to the latest trends and thrive in a rapidly changing
            landscape.
          </p>
          <button className="button" onClick={toggleShow}>
           Click for Technology Contributors
          </button>
        </div>
      ) : (
        <div>
          <div className>
            <h2 className="contributors">Technology Contributors</h2>
            <ol>
              <li><b>
                1.Artificial Intelligence (AI) and Machine Learning
                (ML):</b>Demand Forecasting and Planning, Risk Management and Fraud Detection, Real-time Tracking and Visibility.
              </li>
              <li><b>2.Internet of Things (IoT):</b>AWS IoT Core, Microsoft Azure IoT Hub, Google Cloud IoT Core.</li>
              <li><b>3.Blockchain:</b>Ethereum, Hyperledger Fabric, Corda.</li>
              <li></li>
            </ol>
          </div>
          <button className="button" onClick={toggleShow}>
            Back
          </button>
        </div>
      )}
    </div>
  );
}
