import { React, useEffect } from "react";
import "../SubHeader/SubHeader.scss";
import AOS from "aos";
// import "aos/dist/aos.css"
import BIRDS from "vanta/src/vanta.waves";
function SubHeader(props) {
  useEffect(() => {
    // window.scrollTo(0, 0)
    AOS.init({
      duration:1000,
    });
    AOS.refresh({duration:2000});
    BIRDS({
      el: "#birds",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      color: 0x222a2e,
      shininess: 146.00,
      waveSpeed: 0.90,
      zoom: 1.5
    });
  }, []);
  // useEffect(() => {
  //   AOS.init({
  //     duration: 1000, // values from 0 to 3000, with step 50ms We'll make your new business plan a success !
  //   });
  //   AOS.refresh({ duration: 2000 });
  // }, []);
  return (
    <div className="SubHeader">
      <div className="SubHeader-content my-3">
        <h1
          data-aos="fade-up"
          data-aos-delay="200"
          className="SubHeader-content_heading"
        >
          {props.data.title}
        </h1>
        <div className="container">
          <p
            data-aos="fade-up"
            data-aos-delay="500"
            className="SubHeader-content_subHeading"
          >
            {props.data.description1}
          </p>
          <p
            data-aos="fade-up"
            data-aos-delay="800"
            className="SubHeader-content_subHeading"
          >
            {props.data.description2}
          </p>
          <p
            data-aos="fade-up"
            data-aos-delay="800"
            className="SubHeader-content_subHeading"
          >
            {props.data.description3}
          </p>
        </div>
      </div>
      
        <div className="SubHeader-imageSection">
        <div style={{ height: "600px" }} className="bg" id="birds">
          <div
            // data-aos="fade-up"
            data-aos-delay="900"
            className="SubHeader-image"
            style={{ backgroundImage: `url(${props.data.image})` }}
          ></div>
          {props.data.circlepop ? (
            <div
              data-aos="zoom-out"
              data-aos-delay="1200"
              className="SubHeader-overlayImage"
            >
              <img
                src="https://valiance.qodeinteractive.com/wp-content/uploads/2021/03/h2-button-bg-img1.png"
                alt="Snow"
              />
              <div className="SubHeader-overlayImage_centered">
                <p className="SubHeader-overlayImage_centered-firstText">
                  LET'S TALK
                </p>
                <h3 className="SubHeader-overlayImage_centered-secondText">
                  Contact me
                </h3>
                <a>
                  <i className="fas fa-arrow-right" />
                  {/* <i class="fas fa-circle"></i> */}
                </a>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default SubHeader;
