import React from "react";
import health from "../../Assets/img/industries-served/health.gif";
import ToggleVisibility from "Components/Default/ToggleVisibility.js";
import "Components/Default/default.css";
import Default from "Components/Default/default.js";
import Healthtoggle from "Components/HealthCare/healthtoggle.js"
/* React component where show/hide 
  functionality is implemented */
export default function HealthCare() {
  return (
    <div className="industryalign">
          <div className="row it healthcare flex-sm-column-reverse flex-md-row" style={{ marginTop: "10px" }}>
            <div className="col-md-6 in" data-aos="fade-right" >
              <Healthtoggle/>
            </div>
            <div className="col-md-6 health" data-aos="fade-left">
              <img src={health} alt="auto" className="doc"></img>
            </div>
          </div>
        </div>
  );
}   