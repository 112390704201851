import React from "react";
import Slider from "react-slick";
import Project from "./Project";



let data = [

    {
        img : require("Assets/img/screens/Login page.jpeg"),
        disc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },

    {
        img : require("Assets/img/screens/Login page.jpeg"),
        disc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },

    {
        img : require("Assets/img/screens/Dashboard.jpeg"),
        disc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },

    {
        img : require("Assets/img/screens/Candidates 3.png edit.png"),
        disc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },

    {
        img : require("Assets/img/screens/Add candidates.jpg"),
        disc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },

    {
        img : require("Assets/img/screens/Bulk uploads.jpg"),
        disc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },

    {
        img : require("Assets/img/screens/Job post.jpg"),
        disc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },

    {
        img : require("Assets/img/screens/Add Job post.jpg"),
        disc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },

    {
        img : require("Assets/img/screens/Email transaction.jpg"),
        disc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },

    {
        img : require("Assets/img/screens/Email Templates.jpg"),
        disc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },

    {
        img : require("Assets/img/screens/Whatsapp transaction.jpg"),
        disc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },

    {
        img : require("Assets/img/screens/Users 2.png"),
        disc: "Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    }

    
]


var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};
const SliderComp = () => {

    let sliderProject = "";
    sliderProject = data.map((item, i) => (
        <Project item = {item} key={i}/>
    ))
    return (
        <Slider {...settings}>
           {sliderProject}
        </Slider>
    )
}

export default SliderComp;