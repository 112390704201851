import React from 'react'
import '../WhyKrishpar/WhyKrishpar.scss'
// import Cover from "Components/Cover/Cover";
function WhyKrishpar() {
  return (
    <>
    <div className="container-fluid whykrishpar middle-animated animated">
        <h2 className="animate-charcter" style={{textAlign:'center',paddingLeft:'10em'}}>Why Krishpar?</h2>
        <div className="list-sch">
            <div className="list-content"> <span className="icon">&nbsp;</span><h4 >Recruitment</h4></div>
            <div className="list-content"> <span className="icon">&nbsp;</span><h4>Employee</h4></div>
            <div className="list-content"> <span className="icon">&nbsp;</span><h4>Productive</h4></div>
        </div>
        {/* <button className="button modal-toggle">Join the waitlist</button> */}
        <div className="sch-box">
            <div className="box-content box-1 textup1"><h4>Recruitment</h4>
                <p>A perfect partner for organizations to recruit the best employee.</p>
            </div>
            <div className="box-content box-2 textup2"><h4>Employee</h4>
                <p>A trustable liaison in industry that bridges the gap between renowned organization and valuable employee.</p>
            </div>
            <div className="box-content box-3 textup3"><h4>Productive</h4>
                <p>A suitable navigator for individuals to land in growth oriented career.</p>
            </div>
        </div>
    </div>  
    </>
  )
}

export default WhyKrishpar