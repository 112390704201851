import React from "react";
import it from "../../Assets/img/industries-served/it.gif";
import ToggleVisibility from "Components/Default/ToggleVisibility.js";
import "Components/Default/default.css";
import Default from "Components/Default/default.js";
import Informtoggle from "Components/InformationTechnology/informtoggle.js"
/* React component where show/hide 
  functionality is implemented */
export default function Information() {
  return (
    
    <div className="row it " style={{ marginTop: "10px" }}>
      <div className="col-md-6 tech " div data-aos="fade-right">
        <img src={it} alt="informtech" className="doc"></img>
      </div>
      <div className="col-md-6 in" div data-aos="fade-left">
       <Informtoggle/>
      </div>
    </div>

  );
}   