import React, { useState } from "react";
import "../Sections/Section.css";
import { Container, Row, Col, Button } from "reactstrap";
import Timelineproduct from "Components/Timelineproduct/Timelineproduct";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useEffect } from "react";
import Alerts from "Components/Alert/alert.js";
import Alert from 'react-bootstrap/Alert';

function Section2() {
  const [toggle, setToggle] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
    company: "",
    city: "",
  });

  // const handleInput = () =>{
  //   setFormData({
  //     name: '',
  //     email: '',
  //     mobile: '',
  //     message: '',
  //     company:'',
  //     city:''
  //   });
  // }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateEmail = (email) => {
    // Simple email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateMobileNumber = (mobile) => {
    // Simple mobile number validation using a regular expression
    const mobileRegex = /^[0-9]{10}$/;
    return mobileRegex.test(mobile);
  };

  useEffect(() => {
    setToggle(1);
  }, []);

  useEffect(() => {
    setFormData({
      ...formData,
      form_type:
        toggle == 1
          ? "emploi"
          : toggle == 2
            ? "hr"
            : toggle == 3
              ? "it"
              : "contact",
    });
  }, [toggle]);

  // function formSubmit(){
  //   console.log("Form Submit Trigger");
  //   axios.get('https://emploi.krishpar.com/api/jobs/mail/trigger',{
  //     params: {
  //       auth:"eyJpdiI6IlBXK3dqNlZGbWFDWC9KM2dpckc1K1E9PSIsInZhbHVlIjoiZXlTbm9EeHV1THdLck5lSU42N2Z4d0Q1YjFpbGJxc015VXhaa2tQUURpWT0iLCJtYWMiOiJhYTQzNzUwZjBjZTQyZTAzZGYzZGRhNmNkY2MwNmU0M2ZhYjlhMGVhNTkwN2E5Zjc0MWVmNDE2NDJiZmUzMDNiIiwidGFnIjoiIn0=",
  //       data: formData
  //     },
  //   }).then(response => {
  //       console.log("Response Received");
  //       console.log(response);
  //       handleInput();
  //       alert("form submitted successfully");
  //   }).catch(error => {
  //       console.log("Error Response Received");
  //       console.log(error);
  //       alert("form error");
  //   });
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(formData.email)) {
      setAlertMessage('Please enter a valid email address.');
      setTimeout(() => {
        setAlertMessage('');
      }, 3000);
      return;
    }

    if (!validateMobileNumber(formData.mobile)) {
      setAlertMessage('Please enter a valid 10-digit mobile number.');
      setTimeout(() => {
        setAlertMessage('');
      }, 3000);
      return;
    }


    console.log("Form Submit Trigger");
    axios
      .get("https://emploi.krishpar.com/api/jobs/mail/trigger", {
        params: {
          auth: "eyJpdiI6IlBXK3dqNlZGbWFDWC9KM2dpckc1K1E9PSIsInZhbHVlIjoiZXlTbm9EeHV1THdLck5lSU42N2Z4d0Q1YjFpbGJxc015VXhaa2tQUURpWT0iLCJtYWMiOiJhYTQzNzUwZjBjZTQyZTAzZGYzZGRhNmNkY2MwNmU0M2ZhYjlhMGVhNTkwN2E5Zjc0MWVmNDE2NDJiZmUzMDNiIiwidGFnIjoiIn0=",
          data: formData,
        },
      })
      .then((response) => {
        console.log("Response Received");
        // handleInput();
        // alert("hello");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
        console.log(response);
        const clearedFormData = Object.fromEntries(
          Object.keys(formData).map((key) => [key, ""])
        );
        setFormData(clearedFormData);
      })
      .catch((error) => {
        console.log("Error Response Received");
        console.log(error);
        // alert("form error");
      });
  };
  return (
    <div className="Section">
      <div>
        <ul className="d-flex mb-5">
          <li
            className={toggle === 1 ? "heading active" : "heading"}
            onClick={() => {
              setFormData([]);
              setToggle(1);
            }}
          >
            EMPLOI
          </li>
          {/* <li className="heading"><button>Send Your CV</button></li> */}
          <li
            className={toggle === 2 ? "heading active" : "heading"}
            onClick={() => {
              setFormData([]);
              setToggle(2);
            }}
          >
            HR Solution
          </li>
          <li
            className={toggle === 3 ? "heading active" : "heading"}
            onClick={() => {
              setFormData([]);
              setToggle(3);
            }}
          >
            IT Solution
          </li>
        </ul>

        <div className={toggle === 1 ? "show-content" : "contentss"}>
          <div className="contactusss">
            <Row className="body">
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-right"
                    id="standard-basic"
                    label="Enter your Full Name "
                    variant="standard"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-left"
                    id="standard-basic"
                    label="Email Id  "
                    variant="standard"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row className="body">
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-right"
                    id="standard-basic"
                    label="Mobile Number "
                    variant="standard"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-left"
                    id="standard-basic"
                    label="Company Name"
                    variant="standard"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row className="body2">
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-right"
                    id="standard-basic"
                    label="Message "
                    variant="standard"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-right"
                    id="standard-basic"
                    label="City"
                    variant="standard"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Col xs="auto" className="var2">
              {showAlert && <Alerts />}
              <div className="d-flex justify-content-center" style={{ background: "#fff" }}>
                {alertMessage && <Alert style={{ background: "#d64242", width: "fit-content" }} variant="error">{alertMessage}</Alert>}
              </div>
              <Button type="submit" data-aos="zoom-in" onClick={handleSubmit}>
                Submit
              </Button>

            </Col>
          </div>
        </div>

        <div className={toggle === 2 ? "show-content" : "contentss"}>
          {/* <Timelineproduct/> */}
          <div className="contactusss">
            <Row className="body">
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-right"
                    id="standard-basic"
                    label="Enter your Full Name "
                    variant="standard"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-left"
                    id="standard-basic"
                    label="Business Email Id "
                    variant="standard"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row className="body">
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-right"
                    id="standard-basic"
                    label="Company Name "
                    variant="standard"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-left"
                    id="standard-basic"
                    label="Mobile Number "
                    variant="standard"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row className="body2">
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-right"
                    id="standard-basic"
                    label="Message "
                    variant="standard"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-left"
                    id="standard-basic"
                    label="City "
                    variant="standard"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Col xs="auto" className="var2">
              {showAlert && <Alerts />}
              <div className="d-flex justify-content-center" style={{ background: "#fff" }}>
                {alertMessage && <Alert style={{ background: "#d64242", width: "fit-content" }} variant="error">{alertMessage}</Alert>}
              </div>
              <Button type="submit" data-aos="zoom-in" onClick={handleSubmit}>
                Submit
              </Button>

            </Col>
          </div>
        </div>
        <div className={toggle === 3 ? "show-content" : "contentss"}>
          <div className="contactusss">
            <Row className="body">
              <Col sm={3}>
                {showAlert && <Alerts />}
                <div>
                  <TextField
                    required
                    data-aos="fade-right"
                    id="standard-basic"
                    label="Enter your FullName "
                    variant="standard"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-left"
                    id="standard-basic"
                    label="Business Email id "
                    variant="standard"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row className="body">
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-right"
                    id="standard-basic"
                    label="Company Name "
                    variant="standard"
                    name="company"
                    value={formData.company}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-left"
                    id="standard-basic"
                    label="Mobile Number "
                    variant="standard"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Row className="body2">
              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-right"
                    id="standard-basic"
                    label="Message "
                    variant="standard"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>

              <Col sm={3}>
                <div>
                  <TextField
                    required
                    data-aos="fade-right"
                    id="standard-basic"
                    label="City "
                    variant="standard"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
            </Row>
            <Col xs="auto" className="var2">
              {showAlert && <Alerts />}
              <div className="d-flex justify-content-center" style={{ background: "#fff" }}>
                {alertMessage && <Alert style={{ background: "#d64242", width: "fit-content" }} variant="error">{alertMessage}</Alert>}
              </div>
              <Button type="submit" data-aos="zoom-in" onClick={handleSubmit}>
                Submit
              </Button>

            </Col>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
