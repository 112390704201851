import React, { useState } from "react";
import Default from "Components/Default/default.js";
import construction from "../../Assets/img/industries-served/construction.gif";
export default function ToggleVisibility() {
  // React state to manage visibility
  const [show, setShow] = useState(true);

  // const comp1 = () => {
  //   <div>
  //     <button onClick={toggleShow}>Back</button>
  //     <p>para 1</p>a
  //   </div>;
  // };

  // const comp2 = () => {
  //   return (
  //     <div>
  //       <button onClick={toggleShow}>Tech</button>
  //       <p>para 2</p>
  //     </div>
  //   );
  // };

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);
  }
  // var buttonText = show ? "Technology Contributors" : "Back";

  return (
    <div className="component-container">
      {/* {show && children}
      <button onClick={toggleShow}>{buttonText}</button> */}
      {show ? (
        <div>
          <h3>Construction</h3>
          <p>
            The construction industry is experiencing a digital transformation
            with the adoption of software technologies and digital solutions.
            These innovations are reshaping traditional construction practices
            and enabling companies to adapt to the latest trends. In this
            write-up, we explore some of the cutting-edge software technologies
            and digital solutions that are driving the construction industry
            towards increased efficiency, productivity, and sustainability.
          </p>
          <button className="button" onClick={toggleShow}>
          Click for Technology Contributors
          </button>
        </div>
      ) : (
        <div>
          <div className>
            <h2 className="contributors">Technology Contributors</h2>
            <ol>
              <li>
                <b>1.Artificial Intelligence (AI) and Machine Learning (ML):</b>
                TensorFlow,PyTorch,scikit-learn,Apache Spark,Microsoft Cognitive
                Toolkit (CNTK)
              </li>
              <li>
                <b>2.Internet of Things (IoT):</b>IoT Platforms,Edge
                Computing,IoT Development Frameworks.
              </li>
              <li>
                <b>3.Blockchain:</b> CoinGecko, Chainalysis, Etherscan.
              </li>
              <li>
                <b>4.Cloud Computing:</b>Amazon DynamoDB, Google Cloud
                Firestore, and Azure Cosmos DB
              </li>
            </ol>
          </div>
          <button className="button" onClick={toggleShow}>
            Back
          </button>
        </div>
      )}
    </div>
  );
}
