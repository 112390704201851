import React from "react";
import manufacture from "../../Assets/img/industries-served/manufacture.gif";
import ToggleVisibility from "Components/Default/ToggleVisibility.js";
import "Components/Default/default.css";
import Default from "Components/Default/default.js";
import Manufacttogle from "Components/Manufacturing/manufacttoggle.js"
/* React component where show/hide 
  functionality is implemented */
export default function Manufacturing() {
  return (
    <div className="industryalign">
    <div className="row it">
      <div className="col-md-6 man" data-aos="fade-right">
        <img src={manufacture} alt="manufacturing" className="doc"></img>
      </div>
      <div className="col-md-6 in" data-aos="fade-left">
      <Manufacttogle/>
      </div>
    </div>
  </div>
  );
}   