import React, { useEffect, useState } from "react";
import "../Sections/Section.css"
import { Container, Row, Col } from "reactstrap";
import Timelineproduct from "Components/Timelineproduct/Timelineproduct";
import { MdCloudUpload, MdDelete } from "react-icons/md";
import { AiFillFileImage } from "react-icons/ai";
import Aos from "aos";
import "aos/dist/aos.css"
import { color } from "framer-motion";
import axios from 'axios';

function Section() {

    const [formData, setFormData] = useState({});
    const [toggle, setToggle] = useState(1);

    // const [image, setImage] = useState(null);

    // const [fileName, setFileName] = useState("Browse Files to upload")


    const [fileName, setFileName] = useState('No Selected Files');
    const [image, setImage] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [latestDate, setLatestDate] = useState(null);

    const handleFileChange = (file) => {
        if (file) {
            setFileName(file.name);
            setImage(URL.createObjectURL(file));
        }
    };

    const handleFileDelete = () => {
        setFileName('No Selected Files');
        setImage(null);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform form submission or other operations
    };

    function updateToggle(id) {
        setToggle(id)
    }

    useState(() => {
        Aos.init({ duration: 1500 });
    }, []);

    useEffect(() => {
        axios.get(
            'https://emploi.krishpar.com/api/jobs/getapijobs', 
        {
            params: {
                auth:"eyJpdiI6IlBXK3dqNlZGbWFDWC9KM2dpckc1K1E9PSIsInZhbHVlIjoiZXlTbm9EeHV1THdLck5lSU42N2Z4d0Q1YjFpbGJxc015VXhaa2tQUURpWT0iLCJtYWMiOiJhYTQzNzUwZjBjZTQyZTAzZGYzZGRhNmNkY2MwNmU0M2ZhYjlhMGVhNTkwN2E5Zjc0MWVmNDE2NDJiZmUzMDNiIiwidGFnIjoiIn0="
            }
        }).then(response => {
            console.log("Response Received");
            setJobs(response.data.jobs);
            setLatestDate(response.data.latest_date);
            
        }).catch(error => {
            console.log("Error Response Received");
            console.log(error);
        });
    },[]);

    const jobList = jobs.map((item, i) => (
       
        <div>
            <li>
                <Row className="innertext" data-aos="fade-down">
                
                    <Col lg={8}>
                        <h3>{item.JobTitle}</h3>
                        <p style={{ maxHeight: "90px", overflow: "scroll" }} dangerouslySetInnerHTML={{ __html: item.JobDescription }} />
                    </Col>
                    <Col lg={4}>
                        <a href={item.Link} target="blank"><button>Apply</button></a>
                    </Col>
                </Row>
            </li>
            <hr/>
        </div>
    ))
    function formSubmit(){
        console.log("Form Submit Trigger");
        axios.get(
            'https://emploi.krishpar.com/api/jobs/mail/trigger', 
        {
            params: {
                auth:"eyJpdiI6IlBXK3dqNlZGbWFDWC9KM2dpckc1K1E9PSIsInZhbHVlIjoiZXlTbm9EeHV1THdLck5lSU42N2Z4d0Q1YjFpbGJxc015VXhaa2tQUURpWT0iLCJtYWMiOiJhYTQzNzUwZjBjZTQyZTAzZGYzZGRhNmNkY2MwNmU0M2ZhYjlhMGVhNTkwN2E5Zjc0MWVmNDE2NDJiZmUzMDNiIiwidGFnIjoiIn0=",
                data: formData
            },
        }).then(response => {
            console.log("Response Received");
            console.log(response);
        }).catch(error => {
            console.log("Error Response Received");
            console.log(error);
        });
      }
    return (
        <div className="Section">
            <div className="tab">
                <ul className="d-flex">
                    <li className={toggle === 1 ? "heading active" : "heading"} onClick={() => updateToggle(1)}>EMPLOI FEATURES</li>
                    <li className={toggle === 2 ? "heading active" : "heading"} onClick={() => updateToggle(2)}>AVAILABLE JOBS</li>
                </ul>

                <div className={toggle === 1 ? "show-content" : "contentss"}>
                    <div>
                        <Timelineproduct />

                    </div>


                </div>

                <div className={toggle === 2 ? "show-content" : "contentss"}>
                    <div className="contents">
                        <Container fluid>
                            <Row>
                                <Col lg={8}>
                                    <div className="latestdate">
                                    <h2>Last Updated On : {latestDate}</h2>
                                    </div>
                                    <ul className="list">

                                    {jobList}
                                        {/* <Row className="innertext" data-aos="fade-down">
                                            <Col lg={8}>
                                                <li>Available jobs list2</li>
                                            </Col>
                                            <Col lg={4}>
                                                <button>Apply</button>
                                            </Col>
                                        </Row>
                                        <Row className="innertext" data-aos="fade-down">
                                            <Col lg={8}>
                                                <li>Available jobs list3</li>
                                            </Col>
                                            <Col lg={4}>
                                                <button>Apply</button>
                                            </Col>
                                        </Row>
                                        <Row className="innertext" data-aos="fade-down">
                                            <Col lg={8}>
                                                <li>Available jobs list4</li>
                                            </Col>
                                            <Col lg={4}>
                                                <button>Apply</button>
                                            </Col>
                                        </Row>
                                        <Row className="innertext" data-aos="fade-down">
                                            <Col lg={8}>
                                                <li>Available jobs list5</li>
                                            </Col>
                                            <Col lg={4}>
                                                <button>Apply</button>
                                            </Col>
                                        </Row>
                                        <Row className="innertext" data-aos="fade-down">
                                            <Col lg={8}>
                                                <li>Available jobs list6</li>
                                            </Col>
                                            <Col lg={4}>
                                                <button>Apply</button>
                                            </Col>
                                        </Row>
                                        <Row className="innertext" data-aos="fade-down">
                                            <Col lg={8}>
                                                <li>Available jobs list7</li>
                                            </Col>
                                            <Col lg={4}>
                                                <button>Apply</button>
                                            </Col>
                                        </Row>
                                        <Row className="innertext" data-aos="fade-down">
                                            <Col lg={8}>
                                                <li>Available jobs list8</li>
                                            </Col>
                                            <Col lg={4}>
                                                <button>Apply</button>
                                            </Col>
                                        </Row>
                                        <Row className="innertext" data-aos="fade-down">
                                            <Col lg={8}>
                                                <li>Available jobs list9</li>
                                            </Col>
                                            <Col lg={4}>
                                                <button>Apply</button>
                                            </Col>
                                        </Row>
                                        <Row className="innertext" data-aos="fade-down">
                                            <Col lg={8}>
                                                <li>Available jobs list10</li>
                                            </Col>
                                            <Col lg={4}>
                                                <button>Apply</button>
                                            </Col>
                                        </Row>
                                        <Row className="innertext" data-aos="fade-down">
                                            <Col lg={8}>
                                                <li>Available jobs list11</li>
                                            </Col>
                                            <Col lg={4}>
                                                <button>Apply</button>
                                            </Col>
                                        </Row>
                                        <Row className="innertext" data-aos="fade-down">
                                            <Col lg={8}>
                                                <li>Available jobs list12</li>
                                            </Col>
                                            <Col lg={4}>
                                                <button>Apply</button>
                                            </Col>
                                        </Row>
                                        <Row className="innertext" data-aos="fade-down">
                                            <Col lg={8}>
                                                <li>Available jobs list13</li>
                                            </Col>
                                            <Col lg={4}>
                                                <button>Apply</button>
                                            </Col>
                                        </Row> */}
                                    </ul>
                                </Col>
                                <Col lg={4}>
                                    <div className="resume">
                                        <div className="resumecontent">
                                            <p>If you cannot find your job in the list, please upload your CV in this section.</p>
                                            <form action="" onClick={() => document.querySelector('.input-field').click()}>
                                                <input
                                                    className="input-field"
                                                    type="file"
                                                    accept=".pdf, .docx"
                                                    hidden
                                                    onChange={(e) => handleFileChange(e.target.files[0])}
                                                />
                                                {image ? (
                                                    <div>
                                                        <img className="imagess" src={image} width={60} height={60} alt="filename" />
                                                        <section className="files">
                                                            <AiFillFileImage color="fff" />
                                                            <span className="file">
                                                                {fileName}
                                                                <MdDelete onClick={handleFileDelete} />
                                                            </span>
                                                        </section>
                                                    </div>
                                                ) : (
                                                    <>
                                                        <MdCloudUpload color="#fff" size={60} />
                                                        <section className="files">
                                                            <AiFillFileImage color="fff" />
                                                            <span className="file">{fileName}</span>
                                                        </section>
                                                    </>
                                                )}
                                            </form>

                                            <div className="btns">
                                                <button  onClick={()=> formSubmit()}>SUBMIT</button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>

                            </Row>
                        </Container>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default Section;