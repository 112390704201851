import React from 'react';
import BannerAnim, { Thumb, BgElement } from 'rc-banner-anim';
import QueueAnim from 'rc-queue-anim';
import TweenOne, { TweenOneGroup } from 'rc-tween-one';
import PropTypes from 'prop-types';
import '../ProductBanner/ProductBanner.scss';
import { motion } from "framer-motion";
import ParticleAnimation from '../../Components/Particles/Particle.js';
const Element = BannerAnim.Element;

const icon = {
  hidden: {
    opacity: 0,
    pathLength: 0,
    fill: "rgba(255, 255, 255, 0)"
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: "rgba(255, 255, 255, 1)"
  }
};


let dataArray = [
  {
    heading:"Emploi",
    title: "<b>Revolutionize Your Hiring Process</b>:<b> Embrace Emploi Web-Based Recruitment Software</b>, Solving Recruitment Challenges from Sourcing to Onboarding! Experience the Power of our Web-Based Recruitment Software - <b>Intuitive</b>, <b>Customisable</b>, and <b>Exceptionally Effective!</b>",
    // pic: require('../../Assets/img/screens/Dashboard.jpeg'),
    color: '#FFF43D',
    background: '#F6B429',
    buttonlink: '/emploi-product',
    bgImgNo: 0
  },
  // {
  //   heading:"Emploi",
  //   title: "Say <b>goodbye</b> to the <b>cumbersome</b> and <b>outdated</b> methods of hiring. Our Applicant Tracking and Recruitment Software empowers you to take charge of your hiring process with <b>unrivaled efficiency</b>.",
  //   // pic: require('../../Assets/img/screens/Add Job post.jpg'),
  //   color: '#FF4058',
  //   background: '#FC1E4F',
  //   buttonlink: '/emploi',
  //   bgImgNo: 1
  // },
  {
    heading:'Emploi',
      title: "The key to attracting top talent lies in providing an exceptional candidate experience. With our software, you can create a <b>seamless</b> and <b>user-friendly</b> interface that showcases your company's values and culture.",
      // pic: require('../../Assets/img/screens/Whatsapp transaction.jpg'),
  // pic: require('../ Projectimages/Whatsapptransaction.jpg  '),
  color: '#FFF43D',
  background: '#F6B429',
  buttonlink: '/emploi-product',
  bgImgNo: 0
},
{
  heading:'Emploi', 
    title: "Unlock the potential of <b>artificial intelligence</b> to make informed decisions like never before. Our Applicant Tracking and Recruitment Software leverages <b>advanced AI algorithms</b> to <b>analyze candidate data</b>, <b>identify patterns</b>, and recommend the most suitable candidates for your organization. ",
    // pic: require('../../Assets/img/screens/Email transaction.jpg'),
    color: '#FF4058',
  background: '#FC1E4F',
  buttonlink: '/emploi-product',
  bgImgNo: 0
},
{
  heading:"HR Solutions",
  title: "Elevate your hiring process to <b>unprecedented heights</b> with our Applicant Tracking and Recruitment Software. <b>Embrace efficiency</b>, harness the <b>power of AI</b>, and create a remarkable candidate experience that sets your organization apart.",
  // pic: require('../../Assets/img/banner/recruitment.png'),
  color: '#9FDA7F',
  background: '#64D487',
  buttonlink: '/solutions',
  bgImgNo: 1
},
// {
//     heading:'HR Solutions',
//     title: "<b>Trust</b> and <b>confidentiality</b> are <b>paramount</b> in the recruitment process. Our software comes equipped with <b>state-of-the-art</b> security measures to safeguard sensitive candidate information. Stay compliant with <b>data protection regulations</b> and rest assured that your hiring process is conducted with the utmost <b>professionalism</b>.",
//     // pic: require('../../Assets/img/banner/it-staffing.png'),
//     color: '#9FDA7F',
//     background: '#64D487',
//     buttonlink: '/solutions'
//     },
//     {
//       heading:'HR Solutions',
//         title: "Elevate your hiring process to <b>unprecedented heights</b> with our Applicant Tracking and Recruitment Software. <b>Embrace efficiency</b>, harness the <b>power of AI</b>, and create a remarkable candidate experience that sets your organization apart.",
//       // pic: require('../../Assets/img/banner/out.png'),
//       color: '#9FDA7F',
//       background: '#64D487',
//       buttonlink: '/solutions'
//       },
{
  heading:"IT Solutions",
  title: "Krishpar involves <b>designing, coding</b>, and <b>maintaining</b>  websites that are accessible over the internet.",
  // pic: require('../../Assets/img/It-Solution/ITSolutions.png'),
  color: '#9FDA7F',
  background: '#64D487',
  buttonlink: '/itsolutions',
  bgImgNo: 2
},
{
  heading:'IT Solutions',
    title: "Krishpar Support <b>24x7</b> refers to round-the-clock <b>technical assistance</b> provided to users or organizations to address their IT-related issues.",
    // pic: require('../../Assets/img/It-Solution/itservices.png'),
    color: '#9FDA7F',
    background: '#64D487',
    buttonlink: '/itsolutions',
    bgImgNo: 2
    },
    {
      heading:'IT Solutions',
      title: "Krishpar involves creating applications specifically for a particular <b>mobile platform</b>, such as <b>iOS</b>  or <b>Android</b> . ",
      // pic: require('../../Assets/img/It-Solution/Mobile-Development.png'),
      color: '#9FDA7F',
      background: '#64D487',
      buttonlink: '/itsolutions',
      bgImgNo: 2
      },
      

];
// dataArray = dataArray.map(item => ({ ...item, ...textData }));

class ProductBanner extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  };

  static defaultProps = {
    className: 'product-banner-section',
  };

  constructor(props) {
    super(props);
    this.state = {
      showInt: 0,
      currentSlide: 0,
      bannerImg: require('Assets/img/Hr-Solution/banner1.png'),
      delay: 0,
      imgAnim: [
        { translateX: [0, 300], opacity: [1, 1] },
        { translateX: [0, -300], opacity: [1, 1] },
      ],
    };
    this.oneEnter = false;
  }

  onChange = (e, currentSlide) => {
    this.state.currentSlide = currentSlide;
    if(currentSlide < 3){
      this.setState({ bannerImg: require('Assets/img/Hr-Solution/banner1.png') });
    }else if(currentSlide < 4){
      this.setState({ bannerImg: require('Assets/img/Hr-Solution/banner2.png') });
    }else{
      this.setState({ bannerImg: require('Assets/img/Hr-Solution/banner3.png') });
    }
    if (!this.oneEnter) {
      this.setState({ delay: 300 });
      this.oneEnter = true;
    }
  }

  // onLeft = () => {
  //   let showInt = this.state.showInt;
  //   let currentSlide = this.state.currentSlide;
  //   showInt -= 1;
  //   currentSlide -= 1;
  //   if(currentSlide<0){
  //     currentSlide = dataArray.length-1;
  //   }
  //   const imgAnim = [
  //     { translateX: [0, -300], opacity: [1, 0] },
  //     { translateX: [0, 300], opacity: [1, 0] },
  //   ];
  //   if (showInt <= 0) {
  //     showInt = 0;
  //   }
  //   this.setState({ showInt, imgAnim, currentSlide });
  //   this.bannerImg.prev();
  // };

  // onRight = () => {
  //   let showInt = this.state.showInt;
  //   let currentSlide = this.state.currentSlide;
  //   currentSlide += 1;
  //   if(currentSlide>=dataArray.length){
  //     currentSlide = 0;
  //   }
  //   const imgAnim = [
  //     { translateX: [0, 300], opacity: [1, 0] },
  //     { translateX: [0, -300], opacity: [1, 0] },
  //   ];
  //   showInt += 1;
  //   if (showInt > dataArray.length - 1) {
  //     showInt = dataArray.length - 1;
  //   }
  //   this.setState({ showInt, imgAnim, currentSlide });
  //   this.bannerImg.next();
  // };

  getDuration = (e) => {
    if (e.key === 'map') {
      return 1000;
    }
    return 2000;
  };

  render() {
    const slides = dataArray.map((item, i) => (
      <Element 
        key={i} 
        style={{
          height: '100%',
        }}
        leaveChildHide
       >
        <QueueAnim
          animConfig={this.state.imgAnim}
          duration={this.getDuration}
          delay={[!i ? this.state.delay : 1000, 0]}
          ease={['easeOutCubic', 'easeInQuad']}
          key="slide-wrapper"
        >
          <div className={`${this.props.className}-text text${i}`} key="pic">
            <h1 className='titles heading'>{item.heading}</h1> 
            <h2 className='title' dangerouslySetInnerHTML={{ __html: item.title }}></h2>
            <a className="tit buttons"  type='button' href={item.buttonlink}>Read More</a>
          </div>
        </QueueAnim>
      </Element>));
      const transition = { duration: 4, yoyo: Infinity, ease: "easeInOut" }

    return (
        <>
        <div className={`${this.props.className}-wrapper product-banner`} >
            <video className='bg-video' src={require('Assets/img/banner/productbanner.mp4')} autoPlay muted loop />
            <div className={this.props.className} style={{backgroundImage:`url(${this.state.bannerImg})`}}>
              <BannerAnim
                prefixCls={`${this.props.className}-slide-wrapper`}
                sync
                type="across"
                duration={1000}
                ease="easeInOutExpo"
                arrow={false}
                thumb={false}
                ref={(c) => { this.bannerImg = c; }}
                onChange={this.onChange}
                dragPlay={true}
                autoPlay={true}
                >
                  {slides}
                <Thumb prefixCls="user-thumb" key="thumb">
                  {dataArray.map((img, i) => <span key={"dot-"+i} onClick={this.bannerImg}></span> )}
                </Thumb>
                </BannerAnim>
            {/* <TweenOneGroup enter={{ opacity: 1, type: 'from' }} leave={{ opacity: 1 }}>
            <i className="fa fa-arrow-left" type="left" key="left" onClick={this.onLeft} />
            <i className="fa fa-arrow-right" type="right" key="right" onClick={this.onRight} />
            </TweenOneGroup> */}
        </div>
        </div>
        </>
        );
  }
}

export default ProductBanner;