import React, { useState } from "react";
import Default from "Components/Default/default.js";
import construction from "../../Assets/img/industries-served/construction.gif";
export default function Informtoggle() {
  // React state to manage visibility
  const [show, setShow] = useState(true);

  // const comp1 = () => {
  //   <div>
  //     <button onClick={toggleShow}>Back</button>
  //     <p>para 1</p>a
  //   </div>;
  // };

  // const comp2 = () => {
  //   return (
  //     <div>
  //       <button onClick={toggleShow}>Tech</button>
  //       <p>para 2</p>
  //     </div>
  //   );
  // };

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);
  }
  // var buttonText = show ? "Technology Contributors" : "Back";

  return (
    <div className="component-container">
      {/* {show && children}
      <button onClick={toggleShow}>{buttonText}</button> */}
      {show ? (
        <div>
          <h3 style={{}}>InformationTechnology</h3>
          <p>
            The IT industry is continuously evolving, driven by advancements in
            software technologies and digital solutions. To stay competitive and
            adapt to the latest trends, organizations are leveraging innovative
            tools and platforms that streamline operations, enhance
            productivity, and enable seamless digital transformation. This
            write-up explores some of the cutting-edge software technologies and
            digital solutions that are reshaping the IT industry.
          </p>
          <button className="button" onClick={toggleShow}>
           Click for Technology Contributors
          </button>
        </div>
      ) : (
        <div>
          <div className>
            <h2 className="contributors">Technology Contributors</h2>
            <ol>
              <li>
                <b>1.Cloud Computing:</b>Amazon DynamoDB, Google Cloud
                Firestore, and Azure Cosmos DB
              </li>
              <li>
                <b>2.DevOps:</b>Terraform, AWS CloudFormation, and Azure
                Resource Manager
              </li>
              <li>
                <b>3.Artificial Intelligence (AI) and Machine Learning (ML):</b>{" "}
                TensorFlow,PyTorch,scikit-learn,Apache Spark,Microsoft Cognitive
                Toolkit (CNTK).
              </li>
              <li>
                <b>4.Internet of Things (IoT):</b>IoT Platforms,Edge
                Computing,IoT Development Frameworks.
              </li>
              <li>
                <b>
                  5.Cybersecurity Solutions:
                </b>
                Security Information and Event
                  Management (SIEM) Tools
              </li>
            </ol>
          </div>
          <button className="button" onClick={toggleShow}>
            Back
          </button>
        </div>
      )}
    </div>
  );
}
