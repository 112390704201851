import React from "react";
import engineering from "../../Assets/img/industries-served/engineering.webp";
import EngineeringToggle from "Components/Engineering/engineeringtoggle.js"
import "Components/Default/default.css";

/* React component where show/hide 
  functionality is implemented */
export default function Engineering() {
  return (
    <div className="industryalign">
          <div className="row it" style={{ marginTop: "10px" }}>
            <div className="col-md-6 eng" data-aos="fade-right">
              <img src={engineering} alt="informtech" className="doc"></img>
            </div>
            <div className="col-md-6 in" data-aos="fade-left">
              
             <EngineeringToggle/>
            </div>
          </div>
        </div>
  );
}   