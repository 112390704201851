import React from "react";
import insurance from "../../Assets/img/industries-served/insurance.gif";
import ToggleVisibility from "Components/Default/ToggleVisibility.js";
import "Components/Default/default.css";
import Default from "Components/Default/default.js";
import Intoggle from "Components/Insurance/intoggle.js"
/* React component where show/hide 
  functionality is implemented */
export default function Insurance() {
  return (
    <div className="industryalign">
          <div className="row it" style={{ marginTop: "10px" }}>
            <div className="col-md-6 inhealth" div data-aos="fade-right">
              <img src={insurance} alt="inhealth" className="doc"></img>
            </div>
            <div className="col-md-6 in" div data-aos="fade-left">
            <Intoggle/>
            </div>
            
          </div>
        </div>
  );
}   