import React, { useState } from "react";
import Default from "Components/Default/default.js";
import construction from "../../Assets/img/industries-served/construction.gif";
import Aos from "aos";
import "aos/dist/aos.css"
export default function RetailToggle() {
  // React state to manage visibility
  const [show, setShow] = useState(true);
  useState(() => {
    Aos.init({ duration: 1500 });
}, []);

  // const comp1 = () => {
  //   <div>
  //     <button onClick={toggleShow}>Back</button>
  //     <p>para 1</p>
  //   </div>;
  // };

  // const comp2 = () => {
  //   return (
  //     <div>
  //       <button onClick={toggleShow}>Tech</button>
  //       <p>para 2</p>
  //     </div>
  //   );
  // };

  // function to toggle the boolean value
  function toggleShow() {
    setShow(!show);
  }
  // var buttonText = show ? "Technology Contributors" : "Back";

  return (
    <div className="component-container">
      {/* {show && children}
      <button onClick={toggleShow}>{buttonText}</button> */}
      {show ? (
        <div >
          <h3>Retail</h3>
          <p>
            The retail industry is undergoing a digital transformation, driven
            by innovative software technologies and digital solutions that are
            shaping the way businesses operate and engage with customers. Here
            is a brief overview of the software technologies and latest digital
            solutions that are driving the adaptation to the latest trends in
            the retail industry
          </p>
          <button className="button" onClick={toggleShow}>
           Click for Technology Contributors
          </button>
        </div>
      ) : (
        <div>
          <div className>
            <h2 className="contributors">Technology Contributors</h2>
            <ol>
              <li><b>
                1.Point of Sale (POS) Systems:</b>Cloud-Based POS Software, Mobile POS Apps, Loyalty Program Integration.
              </li>
              <li><b>2.Supply Chain Management Solutions:</b>Internet of Things (IoT) Solutions, Blockchain Technology. </li>
              <li><b>3.Augmented Reality (AR) and Virtual Reality (VR):</b>AR/VR Software Development Kits (SDKs).</li>
              <li><b>4.Omni-Channel Retailing:</b>Point-of-Sale (POS) Systems, Customer Loyalty Programs.</li>
            </ol>
          </div>
          <button className="button" onClick={toggleShow}>
            Back
          </button>
        </div>
      )}
    </div>
  );
}
