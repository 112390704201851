import Alert from 'react-bootstrap/Alert';

function Alerts() {
  return (
    <>
      {[
        // 'primary',
        // 'secondary',
        'success',
        // 'danger',
        // 'warning',
        // 'info',
        // 'light',
        // 'dark',  
      ].map((variant) => (
        <Alert key={variant} variant={variant}>
          successful submission of form!
        </Alert>
      ))}
    </>
  );
}

export default Alerts;