import React, { useEffect } from "react";
import "./HRSolutions.scss";
import AOS from "aos";
import SubHeader from "Components/SubHeader/SubHeader";
import IndexNavbar from "Components/Navbars/IndexNavbar.js";
import Footers from "Components/Footers/Footers";
import {Helmet} from "react-helmet";
function Solution() {
  useEffect(() => {
    AOS.init({
      duration: 1500, // values from 0 to 3000, with step 50ms
    });
    AOS.refresh({ duration: 2000 });
  }, []);

  const data = {
    title: "HR Solutions",
    description1:
      "Transform Your Workforce with KrishPar.! Strategic HR Recruitment and Staffing Solutions.! Unparalleled Expertise in Staffing and Recruitment.!",
    image: require("Assets/img/banner/human-resources.jpg"),
    circlepop: false,
  };

  return (
    <>
      <Helmet>
        <title>HR Solutions - Krishpar Technologies </title>
    </Helmet>
      <IndexNavbar />
      <SubHeader data={data} />
      <div className="HR-solution">
        <div className="row staffing">
          <div className="col-md-6 document" >
            <img
              src={require("Assets/img/Hr-Solution/Contract-staffing.png")}
              alt="staffing"
              className="hrstaff"
            />
          </div>
          <div className="col-md-6 staff" >
            <h3 style={{}}>Contract Staffing</h3>
            <p>
              We have an <b>extensive database</b> and <b>vast resources</b> in
              providing the perfect fit for your project on both long term and
              short term basis. We have everything covered on our product-
              <b>EMPLOI</b>. An error-free, highly secured, and flexible{" "}
              <b>payroll management system</b> that pays employees on time.
              We specializes in <b>Contract Staffing</b>. We
              provides you the human resources you need for a specified period.
              Large and specialized <b>staffing database</b> for FMCG, FMCD,
              Retail, Telecom and Finance.We believe in quality with
              speed. So all the deliverables will be <b>prompt and reported</b>
              with swift actions. Compared to industry standards we will
              establish our presence with a bigger and better contribution.
            </p>
          </div>
        </div>
      </div>
      <div className="HR-solution">
        <div className="row staffing Recruitment flex-sm-column-reverse flex-md-row ">
          <div className="col-md-6 staff" >
            <h3>Recruitment </h3>
            <p>
              Krishpar is a recruitment firm which is the best recruitment
              process <b>outsourcing</b> and <b>staffing agencies</b>. With over
              17 years of experience in manpower recruitment services, we have
              grown over the years to become the well-known manpower recruitment
              agencies. We cater to the manpower recruitment requirements of
              companies ranging from <b>start-ups to large organisations</b> and
              across all industry sectors like IT, Engineering, Manufacturing,
              Pharmaceuticals, and FMCG. From the top fortune{" "}
              <b>500 to small companies</b>, we have the power to transform
              their status/standing with our best job listings and top-notch
              solutions.
            </p>
          </div>
          <div className="col-md-6 document" >
            <img
              src={require("Assets/img/Hr-Solution/recuritment-2.png")}
              alt="Recruitment"
            />
          </div>
        </div>
      </div>
      <div className="HR-solution">
        <div className="row staffing">
          <div className="col-md-6 document" >
            <img
              src={require("Assets/img/Hr-Solution/executivesearchprocess.png")}
              alt="staffing"
            />
          </div>
          <div className="col-md-6 staff" >
            <h3 style={{}}>Executive Search</h3>
            <p>
              Krishpar is a executive search firm collaborates closely with the
              client company to gain a comprehensive understanding of the <b>organization's goals</b>, <b>culture</b>, and the specific
              requirements of the executive position to be filled.The search
              firm utilizes its <b>networks</b>, <b>databases</b>, and <b>industry knowledge</b> to identify potential candidates who match the specified
              criteria.Krishpar's Executive search firms play a crucial role in helping
              companies <b>attract top-tier talent</b>, especially for high-level
              positions where the stakes are high, and the competition for such
              candidates can be fierce.
            </p>
          </div>
        </div>
      </div>
      <div className="HR-solution">
        <div className="row staffing outsourcing flex-sm-column-reverse flex-md-row">
          <div className="col-md-6 staff" >
            <h3>Recruitment Process Outsourcing</h3>
            <p>
              We have a <b>comprehensive strategy</b> to support your functions.
              Your benefits never stop when we spot the right talent, calculate
              risks and reduce cost. Our integrated 360 pro solutions will
              answer every unanswered question regarding RPO. So our process
              meets the <b>highest quality service</b> and achieves{" "}
              <b>100% accuracy – error-free</b>. Our client retention rate of{" "}
              <b>98.9%</b> make us the best Outsourcing Services.
            </p>
          </div>
          <div className="col-md-6 document" >
            <img
              src={require("Assets/img/Hr-Solution/outsourcing.png")}
              alt="Outsourcing"
            />
          </div>
        </div>
      </div>
      <div className="HR-solution">
        <div className="row staffing">
          <div className="col-md-6 document" >
            <img
              src={require("Assets/img/Hr-Solution/Compliance.png")}
              alt="executive"
            />
          </div>
          <div className="col-md-6 staff" data-aos="fade-left">
            <h3 style={{}}>Compliance Management</h3>
            <p>
              Our services are precisely what businesses need to become more{" "}
              <b>effective</b> and <b>well-managed</b>. KrishPar are here to
              navigate you in the right path by handling all the legal issues
              and compliances that you face in your day to day HR life and help
              you to focus on core business operations. If a company or
              organization does not take care, it is exposed to what is called
              as compliance risk. Any business or organisation is bound to
              employ people. Our team, with{" "}
              <b>more than 15 years of experience</b>, is well versed in
              defining policies and procedures to place things in line for you,
              with appropriate laws and regulations. Krishpar will guide to
              examine and execute the right amount of values inclined with
              compliance.
            </p>
          </div>
        </div>
      </div>
      <Footers color="#fff" />
    </>
  );
}

export default Solution;
